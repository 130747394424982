import request from '@/utils/request'

//办卡
export function getCoupon(userId) {
    return request({
        url: '/member/couponuser/getCoupon' + userId,
        method: 'get'
    })
}

export function useCoupon(userId, couponId, data) {
    return request({
        url: '/member/couponuser/wash/useCoupon/' + userId + '/' + couponId,
        method: 'post',
        data: data
    })
}

export function getCouponUserByUserId(userId) {
    return request({
        url: '/member/couponuser/list/couponUser/' + userId,
        method: 'get'
    })
}


