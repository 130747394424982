import { render, staticRenderFns } from "./transferBalance.vue?vue&type=template&id=bd0e91c0&scoped=true&"
import script from "./transferBalance.vue?vue&type=script&lang=js&"
export * from "./transferBalance.vue?vue&type=script&lang=js&"
import style0 from "./transferBalance.vue?vue&type=style&index=0&id=bd0e91c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd0e91c0",
  null
  
)

export default component.exports