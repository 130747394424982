import request from '@/utils/request'

export function storePage(parms) {
  return request({
    url: '/sys/store/page',
    method: 'get',
    params: parms,
  })
}

export function getStoreNum() {
  return request({
    url: '/sys/store/print',
    method: 'get',
  })
}

export function updatePrint(data) {
  return request({
    url: '/sys/store/print',
    method: 'put',
    data: data,
  })
}

// 收银端获取门店改价权限
export function getStorePricingPermission() {
  return request({
    url: '/sys/store/pricing/permission',
    method: 'get'
  })
}

