<template>
  <div class="tabCamera">
    <el-row class="button">
      <el-button type="primary" size="medium" @click="jump">我要拍照</el-button>
    </el-row>
    <el-row style="padding-bottom:20px">
      <div>
       <span style="color:red;margin-bottom: 5px">* 衣物进行拍照或者修改，一定要刷新，否则造成衣物图片无法上传</span>
     </div>
      <div>
        <span class="el-icon-view icon"></span><span>查看图片</span>
        <el-button type="primary" size="small" @click="refreshImage" style="margin-left:10px">刷新</el-button>
      </div>
    </el-row>
    <el-row class="iamge-text" v-if="imgSrc.length == 0">暂无图片</el-row>
    <el-row style="display:flex;flex-wrap: wrap;">
      <div v-for="(url, index) in imgSrc" :key="index" style="width:14%">
        <el-image class="img" :src="url" :preview-src-list="imgSrc"></el-image>
        <div style="padding-left:20px;margin-bottom:10px">
          图片{{ index + 1 }}
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "tabCamera",
  props: {
    cameraClothesId: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imgSrc: [],
      currentTab: this.currentTabValue,
      idValue: this.id,
    };
  },
  created() {
    this.refreshImage();
  },
  methods: {
    jump() {
      if (!this.idValue) {
        this.idValue = Date.now();
      }
      let a =
        window.location.href.split("types")[0] +
        "photoPage?id=" +
        this.cameraClothesId +
        "&imageId=" +
        this.idValue;
      let page = window.open(
        a,
        "收衣拍照",
        "width=" +
          (window.screen.availWidth - 10) +
          ",height=" +
          (window.screen.availHeight - 30) +
          ",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no"
      );
      let _this = this;
      var loop = setInterval(function() {
        if (page&&page.closed) {
          clearInterval(loop);
          _this.refreshImage();
        }
      }, 1000);
    },
    refreshImage() {
      let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
      if (imageObj && imageObj["image" + this.idValue]) {
        this.imgSrc = imageObj["image" + this.idValue].map((item) => item.url);
        this.$emit("fatherMethod", this.idValue);
      } else {
        this.imgSrc = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabCamera {
  padding-left: 30px;

  .button {
    padding: 20px 0 30px 0;
  }

  .icon {
    padding-right: 10px;
    color: #3370ff;
  }

  .iamge-text {
    text-align: center;
    padding-top: 250px;
  }

  .img {
    width: 90px;
    height: 90px;
    padding-right: 15px;
  }

  .icon2 {
    color: #3370ff;
    padding-left: 20px;
    cursor: pointer;
  }

  .icon-warning {
    font-size: 18px;
  }
}
</style>
