<template>
  <div class="prentView orderAddView">
    <div class="topView">
      <div class="backView">
        <el-page-header
            @back="handleAddDraftBox"
            content="收衣类型"
        ></el-page-header>
      </div>
    </div>
    <div class="contentView">
      <div class="leftView" style="padding-right: 500px">
        <div class="menuView">
          <!-- <div class="btnView">
            <button class="btn-service"></button>
          </div> -->
          <div class="collView">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="categoryItem"
                :class="{ select: currentTab === tab.val }"
                @click="toggleTab(tab.val)"
            >
              <label>
                {{ tab.title }}
              </label>
            </div>
          </div>
        </div>
        <!-- 服务-->
        <div
            v-loading="serviceLoading"
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'service'"
        >
          <div
              class="serviceItem boxItem"
              v-for="(item, index) in serviceData.filter(
              (service) => service.sellPrice > 0 && service.vipPrice > 0
            )"
              :key="index"
              @click="changeService(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('service') &&
                orderClothes.selectedClothes[currentActiveSelectedClothesIndex]
                  .service.serviceName === item.serviceName &&
                'border: 2px solid #3370ff;background-color: rgba(239, 244, 255, 100);'
            "
          >
            <div class="serve">
              <div
                  class="nameView"
                  :style="
                  orderClothes.selectedClothes.length > 0 &&
                    orderClothes.selectedClothes[
                      currentActiveSelectedClothesIndex
                    ].hasOwnProperty('service') &&
                    orderClothes.selectedClothes[
                      currentActiveSelectedClothesIndex
                    ].service.serviceName === item.serviceName &&
                    'color: rgb(66, 153, 255);'
                "
              >
                {{ item.serviceName }}
              </div>
              <div class="serve-price" style="text-align: right">
                <div>
                  <span style="">原价:</span
                  ><span style="text-align: right">{{
                    item.serviceId == 13
                        ? item.sellPrice + "%"
                        : "￥" + item.sellPrice
                  }}</span>
                </div>
                <div>
                  <span>会员价:</span
                  ><span style="color: red; text-align: right">{{
                    item.serviceId == 13
                        ? item.vipPrice + "%"
                        : "￥" + item.vipPrice
                  }}</span>
                </div>
                <div v-show="item.activityFlagBool">
                  <span>活动价:</span
                  ><span style="color: red">￥{{ item.activityPrice }}</span>
                </div>
                <!-- <span style="color: rebeccapurple;">
                  {{ item.sellPrice }}
                  <strong style="color: red;">{{ item.vipPrice }}</strong>
                </span> -->
              </div>
            </div>
          </div>
        </div>
        <!--品牌-->
        <div
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'brand'"
        >
          <div class="search">
            <el-button
                type="primary"
                style="margin-right: 15px"
                @click="handlerAddBrand"
            >
              添加品牌
            </el-button>
            <el-input
                v-model="text"
                style="width: 220px"
                placeholder="请输入品牌名称"
                @input="searchBrand"
                clearable
                @clear="searchBrand"
            ></el-input>
            <ol>
              <li v-for="(item, index) in letter" :key="index">
                <el-link style="font-size: 17px" @click="pinSearchBrand(item)">
                  {{ item }}
                </el-link>
              </li>
            </ol>
          </div>

          <div
              class="serviceItem boxItem"
              v-for="(item, index) in brandData"
              :key="index"
              @click="changeBrand(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('brand') &&
                orderClothes.selectedClothes[currentActiveSelectedClothesIndex]
                  .brand.brandName === item.brandName &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="img"><img :src="item.image"/></div>
              <div class="nameView">{{ item.brandName }}</div>
            </div>
          </div>
        </div>
        <!--颜色-->
        <div
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'color'"
        >
          <div class="search">
            <el-button
                type="primary"
                style="margin-right: 15px"
                @click="handlerAddColor"
            >
              添加颜色
            </el-button>
            <el-input
                v-model="text"
                style="width: 220px"
                placeholder="请输入颜色名称"
                @input="searchColor"
                clearable
                @clear="searchColor"
            ></el-input>
            <ol>
              <li v-for="(item, index) in letter" :key="index">
                <el-link style="font-size: 17px" @click="pinyinMatch(item)">
                  {{ item }}
                </el-link>
              </li>
            </ol>
          </div>

          <div
              class="serviceItem boxItem"
              v-for="(item, index) in colorData"
              :key="index"
              @click="selectColor(item)"
              :style="
              orderClothes.selectedClothes.length > 0 && {
                border:
                  orderClothes.selectedClothes[
                    currentActiveSelectedClothesIndex
                  ].hasOwnProperty('color') &&
                  orderClothes.selectedClothes[
                    currentActiveSelectedClothesIndex
                  ].color.colorName === item.colorName
                    ? '2px solid #3370ff'
                    : 'none',
              }
            "
          >
            <div class="cView">
              <div class="color">
                <span class="col" :style="{ background: `${item.hex}` }"></span>
              </div>
              <div class="nameView">{{ item.colorName }}</div>
            </div>
          </div>
        </div>
        <!--瑕疵-->
        <div
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'blemish'"
        >
          <div class="search">
            <el-button
                type="primary"
                style="margin-right: 15px"
                @click="handlerAddBlemish"
            >
              添加瑕疵
            </el-button>
            <el-input
                v-model="text"
                style="width: 220px"
                placeholder="请输入瑕疵名称"
                @input="searchBlemish"
                clearable
                @clear="searchBlemish"
            ></el-input>
            <ol>
              <li v-for="(item, index) in letter" :key="index">
                <el-link
                    style="font-size: 17px"
                    @click="pinSearchBlemish(item)"
                >
                  {{ item }}
                </el-link>
              </li>
            </ol>
          </div>

          <div style="margin: 0 8px 16px 8px; font-weight: 700">污渍：</div>
          <div
              class="serviceItem boxItem"
              v-for="item in blemishData.filter((i) => i.isSerious === 2)"
              :key="item.blemishId"
              @click="changeBlemish(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('blemish') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].blemish.filter((n) => n.blemishId === item.blemishId).length >
                  0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="nameView">{{ item.blemishName }}</div>
            </div>
          </div>
          <div style="margin: 0 8px 16px 8px; font-weight: 700">普通瑕疵：</div>
          <div
              class="serviceItem boxItem"
              v-for="item in blemishData.filter((i) => i.isSerious === 0)"
              :key="item.blemishId"
              @click="changeBlemish(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('blemish') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].blemish.filter((n) => n.blemishId === item.blemishId).length >
                  0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="nameView">{{ item.blemishName }}</div>
            </div>
          </div>
          <div style="margin: 0 8px 16px 8px; font-weight: 700">严重瑕疵：</div>
          <div
              class="serviceItem boxItem"
              v-for="item in blemishData.filter((i) => i.isSerious === 1)"
              :key="item.blemishId"
              @click="changeBlemish(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('blemish') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].blemish.filter((n) => n.blemishId === item.blemishId).length >
                  0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="nameView">{{ item.blemishName }}</div>
            </div>
          </div>
          <div style="margin: 0 8px 16px 8px; font-weight: 700">店长添加：</div>
          <div
              class="serviceItem boxItem"
              v-for="item in blemishData.filter((i) => i.isSerious === 3)"
              :key="item.blemishId"
              @click="changeBlemish(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('blemish') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].blemish.filter((n) => n.blemishId === item.blemishId).length >
                  0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="nameView">{{ item.blemishName }}</div>
            </div>
          </div>
        </div>
        <!--洗后-->
        <div
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'effects'"
        >
          <div class="effects-clothes" style="display: flex;justify-content: center;">
            <el-radio-group disabled v-model="currentTypeId">
              <el-radio-button v-for="(item, index) in categoryList" :label="index" :key="index">
                {{ item.categoryName }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="search">
            <el-button
                type="primary"
                style="margin-right: 15px"
                @click="handlerAddEffect"
            >
              添加洗后风险
            </el-button>
            <el-input
                v-model="text"
                style="width: 220px"
                placeholder="请输入洗后风险名称"
                @input="searchEffects"
                clearable
                @clear="searchEffects"
            ></el-input>
            <ol>
              <li v-for="(item, index) in letter" :key="index">
                <el-link
                    style="font-size: 17px"
                    @click="pinSearchEffects(item)"
                >
                  {{ item }}
                </el-link>
              </li>
            </ol>
          </div>
          <!--                    <div style="margin: 0 8px 16px 8px; font-weight: 700">-->
          <!--                        衣物洗后效果:-->
          <!--                    </div>-->
          <div
              class="serviceItem boxItem"
              v-for="item in effectData"
              :key="item.effectId"
              @click="changeEffect(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('effects') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].effects.filter((n) => n.effectId === item.effectId).length >
                  0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <div class="nameView">{{ item.effectName }}</div>
            </div>
          </div>
        </div>
        <!--附件-->
        <div
            class="boxView listView"
            style="height: calc(100vh - 104px); overflow-y: auto"
            v-if="currentTab === 'accessory'"
        >
          <div
              class="serviceItem boxItem"
              v-for="(item, index) in accessoryData"
              :key="index"
              @click="changeAccessory(item)"
              :style="
              orderClothes.selectedClothes.length > 0 &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].hasOwnProperty('accessory') &&
                orderClothes.selectedClothes[
                  currentActiveSelectedClothesIndex
                ].accessory.filter((n) => n.accessoryId === item.accessoryId)
                  .length > 0 &&
                'border: 2px solid #3370ff;'
            "
          >
            <div class="cView">
              <!--                            <div class="nameView">{{item.effectName}}</div>-->
              <el-badge
                  v-if="
                  orderClothes.selectedClothes.length > 0 &&
                    orderClothes.selectedClothes[
                      currentActiveSelectedClothesIndex
                    ].hasOwnProperty('accessory') &&
                    orderClothes.selectedClothes[
                      currentActiveSelectedClothesIndex
                    ].accessory.filter(
                      (n) => n.accessoryId === item.accessoryId
                    ).length > 0
                "
                  :value="
                  orderClothes.selectedClothes.length > 0 &&
                  orderClothes.selectedClothes[
                    currentActiveSelectedClothesIndex
                  ].hasOwnProperty('accessory') &&
                  orderClothes.selectedClothes[
                    currentActiveSelectedClothesIndex
                  ].accessory.filter((n) => n.accessoryId === item.accessoryId)
                    .length > 0
                    ? orderClothes.selectedClothes[
                        currentActiveSelectedClothesIndex
                      ].accessory.filter(
                        (n) => n.accessoryId === item.accessoryId
                      ).length
                    : 0
                "
                  class="item"
              >
                <div class="nameView">{{ item.accessoryName }}</div>
              </el-badge>
              <el-badge v-else class="item">
                <div class="nameView">{{ item.accessoryName }}</div>
              </el-badge>
            </div>
          </div>
        </div>
        <!--拍照-->
        <tab-camera
            ref="componentCamera"
            @fatherMethod="changePhotos"
            v-if="currentTab === 'camera'"
            :style="{}"
            :cameraClothesId="cameraClothesId"
            :id="id"
        ></tab-camera>
        <!--衣物相关的-->
        <div class="clothesInfo" v-if="currentTab === 'cloth'">
          <div class="leftView listView">
            <div
                v-for="(item, index) in categoryList"
                class="leftItem"
                :ref="'leftItems' + index"
                :key="index"
                :class="{ select: currentTypeId === index }"
                @click="changeTypes(index)"
            >
              {{ item.categoryName }}
            </div>
            <div
                v-if="!getLoading"
                style="height: calc(100vh - 740px);background:#f8f8f8"
            ></div>
          </div>
          <div
              class="conetntView listView"
              v-loading="getLoading"
              element-loading-text="加载中"
          >
            <div
                class="serviceItem boxItem"
                v-for="(item, index) in clothesInfoList"
                :key="index"
                :style="
                item.clothesName === clothesName && 'border: 2px solid #3370ff;'
              "
                @click="selectType(item, index)"
            >
              <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.clothesName"
                  placement="top"
              >
                <div class="cView">
                  <div class="image"><img :src="item.image"/></div>
                  <div class="nameView">{{ item.clothesName }}</div>
                </div>
              </el-tooltip>
              <span
                  class="el-icon-check icon"
                  :style="item.clothesName === clothesName ? '' : 'display:none'"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="rightView">
        <div class="topView">
          <div class="tView">
            <div class="holderView">
              收衣人员：
              <span>{{ storeUserInfo.realName }}</span>
            </div>
            <div class="holderView">
              收衣时间：
              <span>{{ $moment().format("YYYY年MM月DD日") }}</span>
            </div>
            <div class="holderView" v-if="activities.length > 0">
              门店活动：
              <span style="color: red"
              >当前门店正在参与{{
                  activities.length ? activities.length : 0
                }}个活动</span
              >
              <el-button
                  style="margin-left: 20px; padding: 0"
                  type="text"
                  @click="dialogVisible = true"
              >查看
              </el-button>
            </div>
            <!--                        <div class="orderIdView">单号：20082611392995443329</div>-->
            <!--                        <button class="btn-remark"></button>-->
          </div>
          <!-- <div class="customerView">
            <div class="typeView">
              <el-radio label="1" v-model="userInfo.userType + ''">
                散客
              </el-radio>
              <el-radio label="2" v-model="userInfo.userType + ''">
                会员
              </el-radio>
              <label class="label-tip" @click="handleCard">成为会员？</label>
            </div>
          </div> -->
          <div class="user-info">
            <el-avatar
                shape="circle"
                :size="70"
                :fit="fit"
                :src="url ? url : circleUrl"
            ></el-avatar>
            <div class="user-content">
              <div class="name">{{ userInfo.name }}</div>
              <div class="phone">{{ userInfo.phone }}</div>
              <div class="member" v-if="userInfo.userType == '2'">
                <el-image
                    style="width: 22px; height: 18px"
                    :src="vipUrl"
                    :fit="'fill'"
                ></el-image>
                <span class="member-name"> 权益会员</span>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="活动详情" :visible.sync="dialogVisible" width="60%">
          <div
              class="activity"
              v-for="(item, index) in activities"
              :key="index"
          >
            <div class="details">
              <div class="circle">
                <span style="text-align: center; padding-left: 5px">{{
                    index + 1
                  }}</span>
              </div>
              <div style="padding-left: 5px">
                <span
                >活动名称：<span class="details-item">{{
                    item.activityName
                  }}</span></span
                >
              </div>
            </div>
            <p>
              参与分类：<span class="details-item">{{
                item.categoryNames
              }}</span>
            </p>
            <p>
              参加衣物：<span class="details-item">{{
                item.clothesNames
              }}</span>
            </p>
            <p>
              参加服务：<span class="details-item">{{
                item.serviceNames
              }}</span>
            </p>
            <p>
              参加用户：<span class="details-item">{{
                item.userTypeNames
              }}</span>
            </p>
          </div>
        </el-dialog>

        <div id="order-infoListView" class="listView orderInfoView">
          <!-- <div class="memberView" v-if="userInfo.userType === 2">
            <div class="bcView btn-audio bcTag_0">
              <div class="cardTypeView">
                <div>
                  {{ userInfo.cardName }}
                  <div class="line-1"></div>
                  <div class="line-2"></div>
                </div>
              </div>
              <div class="textView">
                <label>姓名：{{ userInfo.name }}</label>
                <label>手机号：{{ userInfo.phone }}</label>
              </div>
              <div class="textView">
                <label>余额：{{ userInfo.balance }}元</label>
              </div>
              <button class="btn-edit"></button>
              <button class="btn-topup" @click="handleRecharge">充值</button>
            </div>
          </div> -->
          <div class="clothes-title">
            <div>衣物名称</div>
            <div>原价</div>
            <div>优惠权益</div>
            <div>小计</div>
          </div>
          <div class="serviceView">
            <div
                class="serviceItem"
                v-for="(item, index) in orderClothes.selectedClothes"
                :key="index"
            >
              <div
                  class="tView"
                  @click.stop="activateSelectedClothes(item, index)"
              >
                <div
                    class="tagsView"
                    :class="
                    currentActiveSelectedClothesIndex === index ? 'active' : ''
                  "
                >
                  <div>
                    <!-- <p>名称</p> -->
                    <p>{{ item.clothesName }}</p>
                  </div>
                  <div v-if="item.hasOwnProperty('service')">
                    <!-- <p>原价</p> -->
                    <p>
                      ￥{{
                        item.service.serviceId != 13
                            ? item.service.sellPrice
                            : Math.round(
                                item.hedgingClothesPrice *
                                (item.service.sellPrice / 100)
                            )
                      }}
                    </p>
                  </div>
                  <div v-if="item.hasOwnProperty('service')">
                    <!-- <p>优惠权益</p> -->
                    <p>
                      ￥{{
                        item.service.serviceId != 13
                            ? item.service.sellPrice - item.realPrice
                            : Math.round(
                                item.hedgingClothesPrice *
                                (item.service.sellPrice / 100)
                            ) -
                            Math.round(
                                item.hedgingClothesPrice *
                                (userInfo.userType == "1"
                                    ? item.service.sellPrice / 100
                                    : item.service.vipPrice / 100)
                            )
                      }}
                    </p>
                  </div>
                  <div v-if="item.hasOwnProperty('service')">
                    <p style="font-weight:700">
                      ￥{{
                        item.service.serviceId != 13
                            ? item.realPrice
                            : Math.round(
                                item.hedgingClothesPrice *
                                (userInfo.userType == "1"
                                    ? item.service.sellPrice / 100
                                    : item.service.vipPrice / 100)
                            )
                      }}
                    </p>
                  </div>
                  <div>
                    <span style="display:flex;align-items: center;">
                      <i
                          class="el-icon-edit-outline"
                          style="
                        color: #3370ff;
                        font-size: 18px;
                        margin-right: 5px;
                      "
                          @click.stop="openEditModal(index)"
                          v-show="
                          pricingPermission &&
                            !continueClothes &&
                            item.hasOwnProperty('service') &&
                            !item.service.activityFlagBool
                        "
                      ></i>
                      <el-button
                          type="text"
                          style="margin-right: 5px; color: #3370ff; font-size: 16px;padding: 1px 0 0 0;"
                          @click.stop="remarksFun(index)"
                      >
                        备注
                      </el-button>
                      <i
                          class="el-icon-delete"
                          v-if="!userInfo.flag || userInfo.flag !== 'update'"
                          v-show="!continueClothes"
                          style="font-size: 18px;color:rgba(178, 178, 178, 100)"
                          @click.stop="deleteSelectedClothesItem(index)"
                      ></i>
                    </span>
                  </div>
                </div>
                <label class="label-number">
                  {{ orderClothes.selectedClothes.length - index }}
                </label>
              </div>
              <div
                  class="empView"
                  v-show="item.hasOwnProperty('service')"
                  @click.stop="goService(index)"
                  :style="{
                  color:
                    currentActiveSelectedClothesIndex === index &&
                    currentTab === 'service'
                      ? 'red'
                      : '',
                }"
              >
                <span class="info_name text-box">服务</span>
                <span class="info_name">
                  {{
                    item.hasOwnProperty("service") && item.service.serviceName
                  }}
                </span>
              </div>
              <div
                  class="empView"
                  :style="{
                  color:
                    currentActiveSelectedClothesIndex === index &&
                    currentTab === 'service'
                      ? 'red'
                      : '',
                }"
                  v-show="
                  item.hasOwnProperty('service') && item.service.squareNum
                "
                  @click.stop="showDialog(index)"
              >
                <span class="info_name text-box">平方数</span>
                <span class="info_name">
                  {{
                    item.hasOwnProperty("service") && item.service.squareNum1
                  }}
                  ×
                  {{
                    item.hasOwnProperty("service") && item.service.squareNum2
                  }}
                  =
                  {{ item.hasOwnProperty("service") && item.service.squareNum }}
                </span>
              </div>
              <div
                  v-show="currentActiveSelectedClothesIndex === index"
                  class="disView"
              >
                <div
                    v-show="item.hasOwnProperty('brand')"
                    @click.stop="goBrand(index)"
                    :style="{
                    color:
                      currentActiveSelectedClothesIndex === index &&
                      currentTab === 'brand'
                        ? 'red'
                        : '',
                  }"
                >
                  <div
                      style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                       margin:3px 0 10px 0;
                    "
                  >
                    <p>
                      <span class="info_name text-box">品牌</span>
                      <span class="info_name">{{
                          item.hasOwnProperty("brand") && item.brand.brandName
                        }}</span>
                    </p>
                    <i
                        class="el-icon-delete"
                        @click.stop="deleteItemBrand(item)"
                    ></i>
                  </div>
                </div>
                <div
                    v-show="item.hasOwnProperty('color')"
                    @click.stop="goColor"
                    :style="{
                    color:
                      currentActiveSelectedClothesIndex === index &&
                      currentTab === 'color'
                        ? 'red'
                        : '',
                  }"
                >
                  <div
                      style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom:6px;
                    "
                  >
                    <p>
                      <span class="info_name text-box">颜色</span>
                      <span class="info_name"
                      >{{
                          item.hasOwnProperty("color") && item.color.colorName
                        }}
                      </span>
                    </p>
                    <i
                        class="el-icon-delete"
                        @click.stop="deleteItemColor(item)"
                    ></i>
                  </div>
                </div>
                <div
                    v-show="
                    item.hasOwnProperty('blemish') && item.blemish.length > 0
                  "
                    @click.stop="goBlemish"
                    style="padding:2px 0"
                    :style="{
                    color:
                      currentActiveSelectedClothesIndex === index &&
                      currentTab === 'blemish'
                        ? 'red'
                        : '',
                  }"
                >
                  <p class="info_name text-box" style="width:40px">瑕疵</p>
                  <div>
                    <p
                        v-for="(blemishItem, blemishIndex) in item.blemish"
                        :key="blemishIndex"
                        style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-left:5px
                      "
                    >
                      <span>{{ blemishItem.blemishName }}</span>
                      <i
                          class="el-icon-delete"
                          @click.stop="deleteBlemishItem(blemishIndex)"
                      ></i>
                    </p>
                  </div>
                </div>
                <div
                    class="empView"
                    style="padding:3px 0 "
                    v-show="
                    item.hasOwnProperty('effects') && item.effects.length > 0
                  "
                    @click.stop="goEffects(index)"
                    :style="{
                    color:
                      currentActiveSelectedClothesIndex === index &&
                      currentTab === 'effects'
                        ? 'red'
                        : '',
                  }"
                >
                  <span class="info_name text-box">洗后</span>
                  <span>
                    <span
                        style="line-height:24px"
                        v-for="(afterItem, afterIndex) in item.effects"
                        :key="afterIndex"
                    >{{
                        afterIndex !== 0 ? "、" : ""
                      }}{{ afterItem.effectName }}
                    </span>
                  </span>
                </div>
                <div
                    class="assEmpView"
                    v-show="
                    item.hasOwnProperty('accessory') &&
                      item.accessory.length > 0
                  "
                    @click.stop="goAccessory"
                    :style="{
                    color:
                      currentActiveSelectedClothesIndex === index &&
                      currentTab === 'accessory'
                        ? 'red'
                        : '',
                  }"
                >
                  <span class="info_name text-box">附件</span>
                  <div class="list_all">
                    <span
                        class="span_list"
                        v-for="(accessoryItem, accessoryIndex) in item.accessory"
                        :key="accessoryIndex"
                    >
                      {{ accessoryItem.accessoryName }}
                      <i
                          class="el-icon-delete"
                          @click="
                          item.accessory = item.accessory.filter(
                            (n, i) => i !== accessoryIndex
                          )
                        "
                      ></i>
                      <!--                                         <img :src="orderDeleteActive" alt=""-->
                      <!--                                              @click="item.accessory = item.accessory.filter((n,i) => i !== accessoryIndex)"/>-->
                    </span>
                  </div>
                </div>
                <div
                    class="empView"
                    style="margin-top: 7px;"
                    v-show="item.hasOwnProperty('remark') && item.remark !== ''"
                    @click.stop="goColor"
                >
                  <span class="info_name text-box">备注</span>
                  <span class="info_name">
                    {{ item.hasOwnProperty("remark") && item.remark }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="amountView" style="width: 480px">
          实付金额：
          <span>{{ total.priceAfterDiscount }}</span>
          <!--          优惠: <span style="font-size: 12px">{{ total.discountPrice }}</span>-->
          <el-divider direction="vertical"></el-divider>
          <div style="display: flex; font-size: 16px; margin-left: 20px">
            <li style="list-style: none; text-align: center">
              <div>件数: {{ orderClothes.selectedClothes.length }}</div>
            </li>
            <li style="list-style: none; text-align: center; margin-left: 10px">
              <div>衣物原价: {{ total.price }}</div>
            </li>
          </div>
        </div>
        <div
            v-if="continueClothes"
            style="font-size: 16px; color: #ccc; margin-top: 5px"
        >
          <span style="color: #e24038">*</span>
          该订单为小程序正常下单,无法修改订单内衣物
        </div>
        <div class="btnView">
          <el-button
              v-if="this.userInfo.flag !== 'update'"
              @click="goOnClothes"
              :disabled="continueClothes"
              type="primary"
          >
            继续收衣
          </el-button>
          <el-button
              v-if="this.userInfo.flag === 'update'"
              @click="updateClothes"
              type="primary"
          >
            确认修改
          </el-button>
          <el-button
              style="background: #e24038; margin-left: 0.1rem"
              v-if="this.userInfo.flag === 'sorting'"
              @click="confirmClothes"
          >
            确认收衣
          </el-button>
          <el-button
              v-if="
              this.userInfo.flag !== 'update' &&
                this.userInfo.flag !== 'sorting'
            "
              @click="handleDetermine"
              style="background: #e24038; margin-left: 0.1rem"
              :loading="settlementDialogVisible"
          >
            收银台
          </el-button>
        </div>
      </div>
    </div>

    <transition name="payFullscreen">
      <div
          class="orderCheckoutView1"
          v-if="payVisible"
          v-loading="payBtnLoading"
          :element-loading-text="payBtnLoadingVal"
      >
        <div class="order-title">
          <div class="title">确定支付</div>
          <span class="el-icon-close close" @click="close"></span>
          <!-- <button class="btn-back" @click="close"></button> -->
        </div>
        <div class="content">
          <div class="leftView" style="margin-right: 420px;padding-left:10px">
            <div
                class="contentView listView"
                style="height: 100%; display: flex"
            >
              <div class="orderInfoView" style="flex: 7">
                <div class="topView" style="padding-left:20px;display:flex">
                  <!-- <div class="timeView">开单时间：{{ currentTime1 | date1 }}</div> -->
                  <div class="discountView" @click="showCouponList">
                    <div class="top">
                      <span class="title">
                        <span class="el-icon-s-ticket icon"></span>
                        <span class="text">使用优惠券</span>
                      </span>
                      <span class="el-icon-arrow-right icon2"></span>
                    </div>
                    <div class="bottom" v-if="couponList.length == 0">
                      暂无优惠券可用
                    </div>
                    <div class="bottom" v-else>
                      优惠券：{{ couponPlaceholder }}
                    </div>
                  </div>
                  <div
                      class="discountView"
                      style="margin-left:20px"
                      v-if="userInfo.grouponType == 2"
                  >
                    <div class="top">
                      <span class="title">
                        <span class="el-icon-s-ticket icon"></span>
                        <span class="text">使用代金券</span>
                      </span>
                    </div>
                    <div class="bottom">
                      {{ userInfo.dealTitle }}
                    </div>
                  </div>
                </div>
                <div class="itemsView" style="height: calc(100vh - 320px);">
                  <div class="toPayView">
                    <ul>
                      <li>
                        序号
                      </li>
                      <li>衣物名称</li>
                      <li>服务</li>
                      <li>金额</li>
                      <li>优惠券</li>
                      <li>预计提成</li>
                      <li>支付信息</li>
                    </ul>
                    <div
                        class="listItem"
                        v-for="(item, index) in selectedClothes"
                        :key="index"
                    >
                      <div class="masterView">
                        <div>
                          {{ index + 1 }}
                        </div>
                        <div>
                          <span class="span-name">{{ item.clothesName }}</span>
                        </div>
                        <div class="nameView">
                          <span class="span-name">
                            {{ item.service.serviceName }}
                          </span>
                        </div>
                        <div class="amtView">
                          <span class="span-name"
                          >￥
                            {{
                              item.service.serviceId != 13
                                  ? item.realPrice
                                  : Math.round(
                                      (item.hedgingClothesPrice *
                                          item.realPrice) /
                                      100
                                  )
                            }}</span
                          >
                        </div>
                        <div class="btnView">
                          <button
                              v-if="item.couponId"
                              class="btn-dyqPay"
                          ></button>
                          <span v-else>未使用</span>
                        </div>
                        <div class="payTypeView">
                          <span class="label-tip">
                            ￥{{ item.commissionPrice }}
                          </span>
                        </div>
                        <div class="payTypeView">
                          <label class="label-tip">
                            {{ payWayName === "" ? "未支付" : payWayName }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bView">
                  <div class="btnView">
                    <!-- <button class="btn-select select">全选</button> -->
                    <!--                                    <button class="btn-split">拆分</button>-->
                    <!--                                    <button class="btn-merge">合并</button>-->
                    <button class="btn-remark" @click="orderRemarkView = true">
                      备注
                    </button>
                  </div>
                  <div class="amtInfoView">
                    <div class="realPayAmt">
                      优惠券:
                      <label>{{ couponName === "" ? "无" : couponName }}</label>
                    </div>
                    <div class="realPayAmt">
                      优惠金额:
                      <label
                          :style="{ color: couponAmount !== 0 ? 'red' : '' }"
                      >
                        ¥ {{ couponAmount }}
                      </label>
                    </div>
                    <div class="realPayAmt" v-if="settlementInfo.activityName">
                      参与活动:
                      <label> {{ settlementInfo.activityName }}</label>
                    </div>
                    <div class="realPayAmt" v-if="settlementInfo.packageName">
                      参与套餐:
                      <label> {{ settlementInfo.packageName }}</label>
                    </div>
                    <div class="toPayAmt">
                      预计提成金额：
                      <label>
                        <span>
                          {{ settlementInfo.expectedTotalCommissionPrice }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="amtInfoView">
                    <div class="payableAmt">
                      原价:
                      <label>¥ {{ settlementInfo.clothesOriginalPrice }}</label>
                    </div>
                    <!--                  <div class="payableAmt">-->
                    <!--                    折扣优惠:-->
                    <!--                    <label>¥ {{ total.discountPrice }}</label>-->
                    <!--                  </div>-->
                    <div
                        class="payableAmt"
                        v-if="settlementInfo.benefitsDiscountPrice"
                    >
                      权益优惠:
                      <label
                      >¥ {{ settlementInfo.benefitsDiscountPrice }}</label
                      >
                    </div>
                    <div
                        class="payableAmt"
                        v-if="settlementInfo.activityDiscountPrice"
                    >
                      活动优惠:
                      <label
                      >¥ {{ settlementInfo.activityDiscountPrice }}</label
                      >
                    </div>
                    <div
                        class="payableAmt"
                        v-if="settlementInfo.packageDiscountPrice"
                    >
                      套餐优惠:
                      <label>¥ {{ settlementInfo.packageDiscountPrice }}</label>
                    </div>
                    <div class="toPayAmt">
                      实付金额：
                      <label>
                        <span>{{ actuallyPrice }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="slide-fade">
                <div
                    style="
                  flex: 4;
                  border-left: 1px solid #e6e6e6;
                  margin-left: 10px;
                  margin-top: 15px;
                "
                    v-if="couponActive && couponList.length != 0"
                >
                  <div class="couponList">
                    <div class="couponList-title">
                      <div style="display:flex">
                        <span class="line"></span>
                        <span>优惠券</span>
                      </div>
                      <div
                          class="el-icon-close"
                          @click="clonesCoupon"
                          style="cursor: pointer"
                      ></div>
                    </div>
                    <div class="couponBox">
                      <el-row
                          @click.native="clickRadio"
                          class="nonuse-coupon"
                          :style="
                          radioValue
                            ? 'border: 1px solid rgba(34, 119, 246, 100)'
                            : ''
                        "
                      >
                        <el-col class="left">不使用优惠券</el-col>
                        <el-col class="right">
                          <div
                              class="radio"
                              :class="radioValue ? 'select-radio' : ''"
                          >
                            <span
                                v-if="radioValue"
                                class="el-icon-check icon"
                            ></span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row
                          class="coupon-content"
                          v-for="(item, index) in couponList"
                          :key="item.id"
                          :style="
                          item.radioValue
                            ? 'border: 1px solid rgba(34, 119, 246, 100)'
                            : ''
                        "
                      >
                        <el-row
                            class="top"
                            @click.native="clickRadioList(index, item.id)"
                        >
                          <el-col class="left">
                            <div>
                              {{ item.couponTypeName }}
                              <span
                                  style="margin-right: 10px;color: #1975FF;font-weight: 700"
                              >{{ item.discountTypeName }}</span
                              >
                            </div>
                            <div>{{ item.couponName }}</div>
                            <div>
                              {{ item.beginTime }} 至 {{ item.endTime }}
                            </div>
                          </el-col>
                          <el-col class="right">
                            <div
                                class="radio"
                                :class="item.radioValue ? 'select-radio' : ''"
                            >
                              <span
                                  v-if="item.radioValue"
                                  class="el-icon-check icon"
                              ></span>
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="center"></el-row>
                        <el-row class="bottom" @click.native="clickRule(index)">
                          <el-col class="text">使用规则</el-col>
                          <el-col
                              v-if="!item.ruleShow"
                              class="el-icon-arrow-down icon"
                          ></el-col>
                          <el-col
                              v-if="item.ruleShow"
                              class="el-icon-arrow-up icon"
                          ></el-col>
                        </el-row>
                        <el-row
                            class="rule-box"
                            :class="
                            item.ruleShow ? 'select-open' : 'select-close'
                          "
                        >
                          <div v-if="item.ruleShow">{{ item.couponDesc }}</div>
                        </el-row>
                      </el-row>
                      <!-- <el-row :gutter="30">
                        <el-col
                          :lg="24"
                          :xl="12"
                          v-for="(item, index) in couponList"
                          :key="index"
                        >
                          <div class="couponItem" @click="useCoupon(item.id)">
                            <span>{{ item.couponTypeName }}</span>
                            <span>{{ item.couponName }}</span>
                            <i>优惠券</i>
                            <div
                              class="selectView"
                              v-if="selectCouponActive == item.id"
                            >
                              <img
                                src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png"
                              />
                            </div>
                          </div>
                        </el-col>
                      </el-row> -->
                    </div>
                    <div class="couponList-footer">
                      <el-button type="primary" @click="enterCoupon"
                      >确定
                      </el-button>
                      <el-button @click="closeCoupon" class="close-button"
                      >取消
                      </el-button>
                      <!-- <el-button type="primary" @click="clearCoupon">
                        不使用优惠券
                      </el-button> -->
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div
              class="rightView"
              v-show="transferBalanceShow"
              style="width: 420px"
          >
            <div class="right-content">
              <div class="receivable-title">应收款</div>
              <div class="receivable-price">¥ {{ actuallyPrice }}</div>
              <div class="line"></div>
              <div class="tView">支付方式</div>
              <div class="paymentView" style="height: 597px">
                <div
                    class="paymentItem listItem"
                    v-for="(item, index) in pay_ways"
                    :key="index"
                    :style="item.bool ? 'pointer-events: none' : ''"
                    @click="choosePayWay(item, index)"
                    v-show="
                    !(
                      orderClothes.packageId != undefined &&
                      orderClothes.packageId != '' &&
                      orderClothes.packageType == '' &&
                      index != 4
                    )
                  "
                >
                  <div
                      v-if="choosePaywayIndex === index"
                      id="loading-mask"
                  ></div>

                  <div
                      v-if="item.name == '通享卡余额' && item.bool"
                      id="loading-disabled"
                  ></div>
                  <div
                      v-if="item.name == '历史余额' && item.bool"
                      id="loading-disabled"
                  ></div>
                  <div
                      v-if="item.name == '企业余额' && item.bool"
                      id="loading-disabled"
                  ></div>
                  <div class="iconView">
                    <label class="label-icon">{{ item.ico }}</label>
                  </div>
                  <div class="textView overflowText">
                    <label class="label-name" v-if="item.name === '历史余额'">
                      {{ item.name }}({{
                        userAccount.balance == null ? 0 : userAccount.balance
                      }})
                    </label>
                    <label
                        class="label-name"
                        v-else-if="item.name === '通享卡余额'"
                    >
                      {{ item.name }}({{
                        userAccount.universalBalance == null
                            ? 0
                            : userAccount.universalBalance
                      }})
                    </label>
                    <label
                        class="label-name"
                        v-else-if="item.name === '企业余额'"
                    >
                      {{ item.name }}({{
                        userAccount.enterpriseAvailableBalance == null
                            ? 0
                            : userAccount.enterpriseAvailableBalance
                      }})
                    </label>
                    <label class="label-name" v-else>{{ item.name }}</label>
                    <label v-if="choosePaywayIndex === index" class="label-amt">
                      应收：
                      <span>{{ actuallyPrice }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="btnView">
                <button class="btn-settle" @click="payBtn">结账</button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-ransfer" v-show="!transferBalanceShow">
          <div>
            <TransferBalance
                :userData="{
                balance: userAccount.balance,
                userId: userInfo.userId,
                userName: userInfo.name,
                phone: userInfo.phone,
                storeId: storeUserInfo.storeId,
              }"
                @close="closeTransferBalance"
                @enter="getcheckOrderClothesInfo"
                @enterBack="enterTransferBalance"
            />
          </div>
        </div>
        <!--支付弹框-->
        <transition name="paidFullscreen">
          <div
              id="qrCodePayPopView"
              class="popView"
              style="width: 480px"
              v-loading="qrCodePayView"
              :element-loading-text="qrCodePayViewName"
              v-if="payWay"
          >
            <div class="popView-bcView" @click="payWay = false"></div>
            <div
                class="popView-contentView"
                style="width: 415px; top: 0; right: 0; overflow: auto"
            >
              <div class="qrCodePayView" style="height: 794px">
                <div class="contentView" style="height: 513px">
                  <div class="amtView">
                    ¥
                    <span>{{ actuallyPrice }}</span>
                  </div>
                  <div class="imgView">
                    <img src="https://www.shouqianba.com/img/logo.svg"/>
                  </div>
                  <div class="qrCodeInputView">
                    <el-input
                        class="input-scanPayCode"
                        placeholder="请扫描或输入付款码"
                        autocomplete="off"
                        ref="pay"
                        v-model="payVal"
                        type="number"
                        autofocus="autofocus"
                    ></el-input>
                    <button @click="submit">收款</button>
                  </div>
                  <div class="tipView">
                    请使用支付宝扫码支付
                    <p>支付过程中，请不要关闭该二维码！</p>
                  </div>
                </div>
              </div>
              <button class="btn-close" @click="payWay = false"></button>
            </div>
          </div>
        </transition>

        <!--美团支付弹框-->
        <el-dialog
            :visible.sync="dialogMeituanCousume"
            width="70%"
            :title="storeUserInfo.storeName"
            class="add-dialog"
            append-to-body
            v-loading="receiptCodePayView"
            :element-loading-text="receiptCodePayViewName"
        >
          <div>
            <div
                style="min-height: 400px; display: flex"
                v-if="receiptInfo.count == 0"
            >
              <div style="width: 30%">
                <el-input
                    ref="receiptCode"
                    v-model="receiptCode"
                    placeholder="请输入团购券码信息"
                    @keyup.enter="scanCodeCoupon"
                />
              </div>
              <div style="margin-left: 20px">
                <el-button
                    type="primary"
                    :disabled="receiptCode == ''"
                    @click="scanCodeCoupon"
                >验券
                </el-button>
              </div>
            </div>
            <div style="min-height: 400px" v-else>
              <div class="shumayanquan">
                该客户在本店购买了1个套餐,请选择需要验券的套餐:
              </div>
              <div class="quanxiangq" style="position: relative; padding: 12px">
                <div>{{ receiptInfo.dealTitle }}</div>
                <div style="position: absolute; bottom: 5px">
                  <span>{{ receiptInfo.dealPrice }}元</span>
                </div>
                <div style="position: absolute; bottom: 5px; right: 5px">
                  <span style="color: #4299ff"
                  >{{ receiptInfo.count }}张券</span
                  >
                </div>
              </div>
              <div>
                <div class="receiptCount">
                  <div style="margin-right: 20px">验券数量:</div>
                  <el-input-number
                      size="mini"
                      :min="0"
                      :max="receiptInfo.count"
                      v-model="orderClothes.receiptNum"
                  />
                </div>
                <div class="receiptBth">
                  <el-button
                      type="primary"
                      style="margin-right: 50px"
                      :disabled="orderClothes.receiptNum == 0"
                      @click="meituanSubmit"
                  >确认消费
                  </el-button>
                  <el-button
                      style="border: 1px solid #dcdfe6"
                      @click="handleCancelCousume"
                  >取消消费
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>

        <!--弹框编辑-->
        <pop-view
            id="order-orderEditPopView"
            :show="orderRemarkView"
            title="备注"
            top="120px"
            width="430px"
            right="25px"
            @cancel="orderRemarkView = false"
        >
          <div class="textView">
            <textarea
                placeholder="填写备注(限100字以内)"
                v-model="orderClothes.orderRemark"
                maxlength="100"
            ></textarea>
          </div>
          <div class="btnView">
            <button @click="orderRemarkView = false">确认</button>
          </div>
        </pop-view>
      </div>
    </transition>
    <!--弹框编辑-->
    <pop-view
        id="order-serviceEditPopView"
        :show="editView"
        title="修改衣物信息"
        @cancel="editView = false"
    >
      <div class="contentView">
        <div class="inputView editPriceView">
          <label>原价:</label>
          <el-input
              v-model.trim="realPrice"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              placeholder="请输入修改价格"
              type="number"
              :disabled="userInfo.flag && userInfo.flag === 'update'"
          ></el-input>
        </div>
        <div class="inputView editPriceView">
          <label>修改原因:</label>
          <el-input
              v-model.trim="modifyReason"
              placeholder="请输入修改原因"
              :disabled="userInfo.flag && userInfo.flag === 'update'"
          ></el-input>
        </div>
        <div class="btnView">
          <button @click="handleEdit">确认</button>
        </div>
      </div>
      <button
          v-if="!userInfo.flag || userInfo.flag !== 'update'"
          class="btn-del"
          @click.stop="
          deleteSelectedClothesItem(currentActiveSelectedClothesIndex)
        "
      >
        删除
      </button>
    </pop-view>
    <!--确认衣物信息-->
    <el-dialog
        title="请确认衣物信息"
        width="68%"
        top="10vh"
        center
        :visible.sync="sortingDialogVisible"
    >
      <el-table
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
          :data="orderSortingInfo.orderClothesSortingList"
          height="350"
          style="font-size: 15px"
          v-loading="orderSortingInfoLoading"
          element-loading-text="加载中"
      >
        <el-table-column property="date" label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            property="clothesName"
            label="衣物"
            width="120"
        ></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.differenceFlag === 0">正常</span>
            <span v-else-if="scope.row.differenceFlag === 3">增加</span>
            <span v-else-if="scope.row.differenceFlag === 4">删除衣物</span>
            <span
                v-else-if="
                scope.row.differenceFlag === 5 || scope.row.differenceFlag === 6
              "
            >
              运费变动
            </span>
            <span v-else>修改衣物</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceName" label="服务">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.serviceName === null ? "--" : scope.row.serviceName
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="品牌">
          <template slot-scope="scope">
            <span>
              {{ scope.row.brandName === null ? "--" : scope.row.brandName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="颜色">
          <template slot-scope="scope">
            <span>
              {{ scope.row.colorName === null ? "--" : scope.row.colorName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="附件">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.accessoryNum === null ? "--" : scope.row.accessoryNum
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="currentPrice" label="应付"></el-table-column>
        <el-table-column property="paidAmount" label="已付">
          <template slot-scope="scope">
            <span>
              {{ scope.row.paidAmount === null ? "0" : scope.row.paidAmount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="differenceCurrentPrice" label="差额需退">
          <template slot-scope="scope">
            <span v-if="scope.row.differenceFlag === 0">0</span>
            <span v-if="scope.row.differenceFlag === 1">
              {{
                scope.row.differenceFlag === 1
                    ? scope.row.differenceCurrentPrice
                    : 0
              }}
            </span>
            <span v-if="scope.row.differenceFlag === 2">0</span>
            <span v-if="scope.row.differenceFlag === 3">0</span>
            <span v-if="scope.row.differenceFlag === 4">
              {{ scope.row.currentPrice }}
            </span>
            <span v-if="scope.row.differenceFlag === 5">0</span>
            <span v-if="scope.row.differenceFlag === 6">
              {{ scope.row.differenceCurrentPrice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="differenceCurrentPrice" label="差额需补">
          <template slot-scope="scope">
            <span v-if="scope.row.differenceFlag === 0">0</span>
            <span v-if="scope.row.differenceFlag === 1">0</span>
            <span v-if="scope.row.differenceFlag === 2">
              {{
                scope.row.differenceFlag === 2
                    ? scope.row.differenceCurrentPrice
                    : 0
              }}
            </span>
            <span v-if="scope.row.differenceFlag === 3">
              {{ scope.row.differenceFlag === 3 ? scope.row.currentPrice : 0 }}
            </span>
            <span v-if="scope.row.differenceFlag === 4">0</span>
            <span v-if="scope.row.differenceFlag === 5">
              {{ scope.row.differenceCurrentPrice }}
            </span>
            <span v-if="scope.row.differenceFlag === 6">0</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="f-details">
        <el-row :gutter="20">
          <el-col :span="4">
            <div>{{ orderSortingInfo.clothesNum }}</div>
            <div class="tit">衣物件数</div>
          </el-col>
          <el-col :span="5">
            <div>{{ orderSortingInfo.paidAmount }}</div>
            <div class="tit">
              已付金额
              <span v-if="orderSortingInfo.containsFareFlag"
              >(含运费 {{ orderSortingInfo.afterFare }})</span
              >
            </div>
          </el-col>
          <el-col :span="5">
            <div>{{ orderSortingInfo.actuallyPaidPrice }}</div>
            <div class="tit">应付金额</div>
          </el-col>
          <el-col :span="5">
            <div style="color: #3370FF">
              {{
                orderSortingInfo.updateFlag === 1
                    ? orderSortingInfo.updatePrice
                    : 0
              }}
            </div>
            <div style="color: #3370FF">总额应退</div>
          </el-col>
          <el-col :span="5">
            <div style="color: red">
              {{
                orderSortingInfo.updateFlag === 2
                    ? orderSortingInfo.updatePrice
                    : 0
              }}
            </div>
            <div style="color: red" class="tit">总额应补</div>
          </el-col>
        </el-row>
      </div>
      <div
          style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 18px;
        "
      >
        <div>
          <el-button
              style="width: 100px"
              type="primary"
              @click="sortingDialogVisible = false"
          >
            返回
          </el-button>
        </div>
        <div style="margin-left: 15px">
          <el-button
              style="width: 100px"
              type="danger"
              @click="handlerConfirmClothes"
          >
            确认收衣
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 填写备注 -->
    <el-dialog
        title="备注"
        :visible.sync="remarksDialogVisible"
        class="note"
        width="500px"
    >
      <el-input
          type="textarea"
          :rows="3"
          maxlength="50"
          show-word-limit
          placeholder="请输入备注"
          v-model="remark"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="remarksDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="remarksDialogFun">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 地毯平方数弹窗 -->
    <el-dialog
        title="请输入尺寸"
        :visible.sync="carpetDialogVisible"
        :show-close="false"
        width="700px"
        class="size-dialog"
    >
      <el-row>
        <el-col :span="6">
          <el-input-number
              v-model="squareNum1"
              controls-position="right"
              label="长"
              @change="numberChange"
              :precision="2"
              :min="0"
              :step="0.1"
              :max="1000"
          ></el-input-number>
        </el-col>
        <el-col :span="2" style="text-align: center; line-height: 3">
          ×
        </el-col>
        <el-col :span="6">
          <el-input-number
              v-model="squareNum2"
              controls-position="right"
              label="宽"
              @change="numberChange"
              :precision="2"
              :min="0"
              :step="0.1"
              :max="1000"
          ></el-input-number>
        </el-col>
        <el-col :span="2" style="text-align: center; line-height: 3">
          =
        </el-col>
        <el-col :span="6">
          <el-input v-model="squareNumber" type="number" :disabled="true">
            <template slot="append">㎡</template>
          </el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="editSquare">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 保值洗弹框 -->
    <el-dialog
        title="保值洗计算价格"
        class="valueWash"
        :visible.sync="valueWashDialogVisible"
        width="40%"
        center
    >
      <div style="margin-bottom: 10px">
        <span class="current_user">当前用户身份:</span>
        <span class="span_box">{{
            userInfo.userType == "1" ? "散客" : "会员"
          }}</span>
      </div>
      <div style="margin-bottom: 20px">
        <span class="current_rate">当前收费比:</span>
        <span class="span_box"
        >{{
            userInfo.userType == "1"
                ? valueWashItem.sellPrice
                : valueWashItem.vipPrice
          }}%</span
        >
      </div>
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="衣物价值" prop="clothingPrices">
          <el-input-number
              v-model.number="form.clothingPrices"
              @change="changeInputNumber"
              :min="0"
              :max="20000"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="衣物洗涤费" style="text-align: left">
          <span style="padding-left: 10px"
          >￥{{ form.clothingPrices ? form.cost : 0 }}</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="valueWashDialogVisible = false">取 消</el-button>
        <el-button
            type="primary"
            :disabled="!form.clothingPrices"
            @click="enterValueWashPrice"
        >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 结账成功弹框 -->
    <el-dialog
        :visible.sync="settleAccountsDialogVisible"
        width="420px"
        class="settle-accounts"
        top="0"
        center
        @close="closeSettleAccounts"
    >
      <PaySuccess ref="PaySuccess"/>
    </el-dialog>

    <!--服务提示-->
    <el-dialog
        :title="serviceDialogItem.title"
        :visible.sync="serviceDialogVisible"
        class="service-dialog"
        width="30%"
        center>
      <span>{{ serviceDialogItem.body }}</span>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleService()">确 定</el-button>
                <el-button @click="serviceDialogVisible = false">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import vip from "@/assets/img/vip.png";
// import FullScreenDialog from "@/components/FullScreenDialog";
// import CheckoutSuccess from "@/components/CheckoutSuccess";
import {
  getClothesInfoList,
  getClothesServiceList,
  getClothesColorList,
  getClothesBlemishList,
  getClothesAccessoryList,
  getClothesBrandList,
  getClothesEffectList,
  updateOrder,
  addDraftbox,
  deleteClothesDraftboxs,
  calculateClothesInfo,
  updateOrderClothes,
  addOrder,
  // getOrderCommission,
  listQuery,
  clothingIdentification,
  // clothesCheck,
  getActiveByStore,
  getClothesEffectListByCategoryId
} from "@/api/clothes/clothes";
import {
  payOrder,
  payOrderQuery,
  getClothesOrderDetails,
  checkOrderClothesInfo,
} from "@/api/clothes/order";
import {getStorePricingPermission} from "@/api/store/store";
import {useCoupon} from "@/api/member/coupon";

import PopView from "@/components/PopView";
import tabCamera from "@/view/get/components/tabCamera";
import TransferBalance from "@/view/get/components/transferBalance";
import pinyin from "pinyin-match";
import orderDeleteActive from "_img/icon-order-delete-active.png";
import {getLodop} from "@/print/LodopFuncs";
import {mapGetters} from "vuex";
import {scanCodeCoupon} from "@/api/meituan";
import PaySuccess from "./paySuccess";

export default {
  name: "getTypes",
  components: {
    // FullScreenDialog,
    // CheckoutSuccess,
    PopView,
    tabCamera,
    TransferBalance,
    PaySuccess,
  },
  data() {
    let validateClothingPrices = (rule, value, callback) => {
      if (value >= 5000 && value <= 20000) {
        callback();
      } else {
        callback(new Error("请输入5000~20000之间的衣物价格"));
      }
    };
    return {
      settleAccountsDialogVisible: false,
      vipUrl: vip,
      fit: "fill",
      url:
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      circleUrl:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      orderDeleteActive,
      payVisible: false,
      successVisible: false,
      remarksDialogVisible: false,
      carpetDialogVisible: false,
      couponActive: false,
      selectCouponActive: "",
      squareNumber: "",
      squareNum1: "",
      squareNum2: "",
      isColor: false,
      // squareNumberList:[], //保存平方数的数组
      itemClothes: {},
      tabs: [
        {
          val: "cloth",
          title: "衣物",
        },
        {
          val: "service",
          title: "服务",
        },
        {
          val: "brand",
          title: "品牌",
        },
        {
          val: "color",
          title: "颜色",
        },
        {
          val: "blemish",
          title: "瑕疵",
        },
        {
          val: "effects",
          title: "洗后风险",
        },
        {
          val: "accessory",
          title: "附件",
        },
        {
          val: "camera",
          title: "拍照",
        },
      ],
      catTabs: [
        {
          id: 0,
          title: "上衣",
        },
        {
          id: 1,
          title: "鞋类",
        },
        {
          id: 2,
          title: "裤装裙类",
        },
      ],
      currentTab: "cloth",
      currentTypeId: 0,
      clothesInfo: true,
      otherInfo: false,
      payWay: false,
      categoryList: [], //全部的数据
      categoryId: '',
      clothesInfoList: [],
      clothesName: "",
      orderClothes: {
        userId: "",
        phone: "",
        name: "",
        address: "",
        userType: 1,
        price: 0,
        payWay: "",
        authCode: "",
        orderSource: "",
        selectedClothes: [],
        orderRemark: "",
        receiptNum: 0,
        packageId: "",
        useService: "",
        orderType: "",
        clothesNum: "",
        dealTitle: "",
        receiptCode: "",
        packageType: "",
        couponIdList: [],
      },
      // 服务
      serviceData: [],
      effectData: [],
      colorData: [],
      blemishData: [],
      accessoryData: [],
      brandData: [],
      currentActiveSelectedClothesIndex: 0,
      // 用户信息
      userInfo: {},
      // 支付方式
      pay_ways: [
        // {
        //   name: "企业余额",
        //   ico: "团",
        //   label: 13,
        //   bool: false,
        // },
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
          bool: false,
        },
        {
          name: "洗后付",
          ico: "后",
          label: 5,
          bool: false,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
        {
          name: "美团/点评",
          ico: "美",
          label: 7,
          bool: false,
        },
        {
          name: "抖音支付",
          ico: "抖",
          label: 10,
          bool: false,
        },
      ],
      // 选中支付方式
      choosePaywayIndex: -1,
      // 支付名称
      payWayName: "",
      // 创建订单相关
      outTradeNo: "",
      orderNumber: "",
      // 付款码付款
      qrCodePayView: false,
      // 付款码提示
      qrCodePayViewName: "收款中...",
      payBtnLoading: false,
      payBtnLoadingVal: "收衣中...",
      payVal: "",
      myInterval: null,
      shouInterval: null,
      editView: false,
      remark: "",
      realPrice: "",
      modifyReason: "",
      originalPrice: "", // 原始价格
      orderSortingInfo: {},
      sortingDialogVisible: false,
      orderSortingInfoLoading: false,
      // 余额 优惠券行为
      userAccount: {},
      couponPlaceholder: "暂无可用优惠券",
      couponList: [], //优惠券列表
      couponName: "",
      couponAmount: 0,
      valDiscount: "",
      selectedClothes: [],
      activityIdList: [],
      // 搜索
      letter: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
      // 添加
      copyColorData: [],
      copyBlemishData: [],
      copyAccessoryData: [],
      copyEffectsData: [],
      copyBrandData: [],
      text: "",
      orderDetailOption: {},
      orderRemarkView: false,
      currentTime: this.getCurrentTime(),
      getLoading: true,
      isCamera: false,
      continueClothes: false,
      currentTime1: new Date(),
      timer: "",
      commissionPrice: [],
      // 美团
      dialogMeituanCousume: false,
      receiptCode: "",
      receiptInfo: {
        appDealId: "1",
        count: 0,
        dealPrice: "1",
        dealTitle: "1",
        receiptCode: "",
      },
      receiptCodePayView: false,
      receiptCodePayViewName: "验券中...",
      dialogVisible: false,
      activities: [],
      // 支付页面结算
      settlementInfo: null,
      settlementDialogVisible: false,
      // 实付金额
      priceAfterDiscount: 0,
      pricingPermission: false,
      cameraClothesId: null,
      id: null,
      valueWashDialogVisible: false,
      form: {
        clothingPrices: 0,
      },
      valueWashItem: {},
      rules: {
        clothingPrices: [
          {
            required: true,
            validator: validateClothingPrices,
            trigger: ["blur", "change"],
          },
        ],
      },
      serviceLoading: false,
      code: "",
      idShow: false,
      imageBool: true,
      imageObj: {},
      transferBalanceShow: true,
      radioValue: null,
      // 已经选择的优惠券
      chooseCouponList: [],
      imageArr: [],
      // 洗后效果
      radio1: '上海',
      cities: ['上海', '北京', '广州', '深圳'],
      serviceDialogVisible: false,
      serviceItemValue: {},
      serviceDialogItem: {
        title: '',
        body: ''
      }
    };
  },
  watch: {
    // 每次切换tab都会执行一次
    currentTab(newVal) {
      //判断是否有选择衣物
      if (newVal !== "cloth" && this.orderClothes.selectedClothes.length <= 0) {
        this.$message.error("请先选择衣物");
        this.currentTab = "cloth";
        return;
      }
      // 服务
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];

      //选择商品时（洗衣液），无法继续收衣
      if (
          newVal !== "service" && theObjectKey
              ? !theObjectKey.hasOwnProperty("service")
              : "" && theObjectKey
                  ? !theObjectKey?.service
                  : ""
      ) {
        this.$message.error("请选择该件衣物的服务");
        this.currentTab = "service";
        return;
        // 颜色
      }
      if (newVal === "service") {
        this.getServiceList();
      } else if (newVal === "color") {
        getClothesColorList().then((response) => {
          this.colorData = response.data.data;
          this.copyColorData = response.data.data;
        });
      } else if (newVal === "blemish") {
        getClothesBlemishList().then((response) => {
          this.blemishData = response.data.data;
          this.copyBlemishData = response.data.data;
        });
      } else if (newVal === "accessory") {
        getClothesAccessoryList().then((response) => {
          this.accessoryData = response.data.data;
        });
        // 品牌
      } else if (newVal === "brand") {
        getClothesBrandList().then((response) => {
          this.brandData = response.data.data.brandList;
          this.copyBrandData = response.data.data.brandList;
        });
      } else if (newVal === "effects") {
        getClothesEffectListByCategoryId(this.categoryId).then((response) => {
          this.effectData = response.data.data;
          this.copyEffectsData = response.data.data;
        });
        // 拍照
      } else if (newVal === "camera") {
        // this.$refs.componentCamera.show()
        this.$nextTick(() => {
          // this.$refs.componentCamera.getCompetence();
          this.isCamera = true;
          if (this.orderClothes.selectedClothes.length > 0) {
            const theObjectKey = this.orderClothes.selectedClothes[
                this.currentActiveSelectedClothesIndex
                ];
            if (Object.prototype.hasOwnProperty.call(theObjectKey, "photos")) {
              this.$refs.componentCamera.imgSrc = theObjectKey.photos;
            } else {
              this.$refs.componentCamera.imgSrc = [];
            }
          }
        });
      }
    },
    $route: {
      immediate: true,
      handler(value) {
        if (value.query.data) {
          let data = JSON.parse(value.query.data);
          if (data.selectedClothes && data.selectedClothes.length != 0) {
            this.cameraClothesId = data.selectedClothes[0]?.clothesId;
          }
        }
        if (value.params.data) {
          let data = JSON.parse(value.params.data);
          if (data.selectedClothes && data.selectedClothes.length != 0) {
            this.cameraClothesId = data.selectedClothes[0]?.clothesId;
            this.clothesId = data.selectedClothes[0]?.clothesId;
          }
        }
      },
    },
  },
  computed: {
    total: function () {
      // 折扣价格
      let priceAfterDiscount = 0;
      let discountPrice = 0;
      // 原价
      let price = 0;
      let value = 0;
      this.orderClothes.selectedClothes.forEach((item) => {
        let userType = this.userInfo.userType;
        if (Object.prototype.hasOwnProperty.call(item, "service")) {
          // 为true进行平方计算
          if (item.service.calculation) {
            if (item.service.activityFlagBool) {
              price = price + item.service.sellPrice;
              // parseFloat(item.service.sellPrice) * item.num * item.squareNum;
            } else {
              if (item.service.serviceId == 13) {
                price =
                    price +
                    Math.round(
                        item.hedgingClothesPrice *
                        (parseFloat(item.service.sellPrice) / 100)
                    ) *
                    item.num *
                    item.squareNum;
                //平方数计算价格
                // 散客
              } else {
                price =
                    price +
                    parseFloat(item.service.sellPrice) *
                    item.num *
                    item.squareNum;
                //平方数计算价格
                // 散客
              }
            }
            if (item.service.activityFlagBool) {
              priceAfterDiscount =
                  priceAfterDiscount +
                  Math.round(
                      parseFloat(item.service.activityPrice) *
                      item.num *
                      item.squareNum
                  );
            } else {
              if (userType == 1) {
                if (item.service.serviceId == 13) {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      Math.round(
                          item.hedgingClothesPrice *
                          (parseFloat(item.service.sellPrice) / 100)
                      ) *
                      item.num *
                      item.squareNum;
                } else {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      parseFloat(item.service.sellPrice) * item.num *
                      item.squareNum;
                }
              } else {
                // 会员
                if (item.service.serviceId == 13) {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      Math.round(
                          item.hedgingClothesPrice *
                          (parseFloat(item.service.vipPrice) / 100)
                      ) *
                      item.num *
                      item.squareNum;
                } else {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      parseFloat(item.service.vipPrice) *
                      item.num *
                      item.squareNum;
                }
              }
              console.log(priceAfterDiscount)
            }
          } else {
            if (item.service.serviceId == 13) {
              price =
                  price +
                  Math.round(
                      item.hedgingClothesPrice *
                      (parseFloat(item.service.sellPrice) / 100)
                  ) *
                  item.num;
            } else {
              price = price + parseFloat(item.service.sellPrice) * item.num;
            }
            // 判断是否为活动价
            if (item.service.activityFlagBool) {
              if (item.service.serviceId == 13) {
                priceAfterDiscount =
                    priceAfterDiscount +
                    Math.round(
                        item.hedgingClothesPrice *
                        (parseFloat(item.service.activityPrice) / 100)
                    ) *
                    item.num;
              } else {
                priceAfterDiscount =
                    priceAfterDiscount +
                    parseFloat(item.service.activityPrice) * item.num;
              }
            } else {
              // 散客
              if (userType == 1) {
                if (item.service.serviceId == 13) {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      Math.round(
                          item.hedgingClothesPrice *
                          (parseFloat(item.service.sellPrice) / 100)
                      ) *
                      item.num;
                } else {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      parseFloat(item.service.sellPrice) * item.num;
                }
              } else {
                // 会员
                if (item.service.serviceId == 13) {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      Math.round(
                          item.hedgingClothesPrice *
                          (parseFloat(item.service.vipPrice) / 100)
                      ) *
                      item.num;
                } else {
                  priceAfterDiscount =
                      priceAfterDiscount +
                      parseFloat(item.service.vipPrice) * item.num;
                }
              }
            }
          }
          if (this.userInfo?.code == "caogao") {
            value = value + parseFloat(item.realPrice) * item.num;
            priceAfterDiscount = value;
          }
        }
      });
      priceAfterDiscount = parseFloat(
          priceAfterDiscount - Number(this.couponAmount)
      ).toFixed(2);
      price = parseFloat(price).toFixed(2);
      discountPrice = price - priceAfterDiscount;
      discountPrice = parseFloat(discountPrice).toFixed(2);
      return {
        discountPrice,
        priceAfterDiscount,
        price,
      };
    },
    // 优惠券计算
    actuallyPrice: function () {
      let actuallyPaidPrice = this.priceAfterDiscount;
      return parseFloat(actuallyPaidPrice - Number(this.couponAmount)).toFixed(
          2
      );
    },
    ...mapGetters({
      storeUserInfo: "userInfo",
    }),
  },
  created() {
    // 刚打开页面的时候默认是选中“衣物”tab的，这里要提前向后台拿
    // this.getClothesCategoryList()
    // this.changeTypes(0);
    // 获取传来的参数 自助下单
    if (this.$route.query.data) {
      this.userInfo = JSON.parse(this.$route.query.data);
      if (this.userInfo.packageId != "" && this.userInfo.packageId != null) {
        // 1：美团 2：自营 3: 抖音
        if (this.userInfo.packageType === 2) {
          this.pay_ways = [
            {
              name: "通享卡余额",
              ico: "通",
              label: 11,
              bool: false,
            },
            {
              name: "历史余额",
              ico: "余",
              label: 3,
              bool: false,
            },
            {name: "现金", ico: "现", label: 4, bool: false},
            {name: "收钱吧", ico: "收", label: 6, bool: false},
          ];
        } else if (this.userInfo.packageType === 1) {
          this.pay_ways = [
            {name: "美团/点评", ico: "美", label: 7, bool: false},
          ];
        } else {
          if (this.userInfo.grouponType == 2) {
            this.pay_ways = [
              {
                name: "通享卡余额",
                ico: "通",
                label: 11,
                bool: false,
              },
              {
                name: "历史余额",
                ico: "余",
                label: 3,
                bool: false,
              },
              {name: "现金", ico: "现", label: 4, bool: false},
              {name: "收钱吧", ico: "收", label: 6, bool: false},
            ];
          } else {
            this.pay_ways = [
              {name: "抖音支付", ico: "抖", label: 10, bool: false},
            ];
          }
        }
        this.packageClothingType(this.userInfo.packageId);
      } else {
        this.pay_ways = this.pay_ways.filter((item) => {
          if (item.label != 7 && item.label != 10) {
            return item;
          }
        });
        this.getClothesCategoryList();
      }
      this.getActiveByStore();
      this.orderClothes.userId =
          this.userInfo.userId === null ? "0" : this.userInfo.userId;
      this.orderClothes.phone = this.userInfo.phone;
      this.orderClothes.name = this.userInfo.name;
      this.orderClothes.address = this.userInfo.address;
      this.orderClothes.userType = this.userInfo.userType;
      this.orderClothes.useService = this.userInfo.useService;
      this.orderClothes.packageId = this.userInfo.packageId;
      // this.orderClothes.orderType = this.userInfo.orderType
      this.orderClothes.clothesNum = this.userInfo.clothesNum;
      this.orderClothes.dealTitle = this.userInfo.dealTitle;
      this.orderClothes.receiptCode = this.userInfo.receiptCode;
      this.orderClothes.packageType = this.userInfo.packageType;

      if (
          this.userInfo.flag === "sorting" &&
          this.userInfo.oneClickOrder == 1
      ) {
        this.continueClothes = true;
      } else {
        this.continueClothes = false;
      }
      // 修改
      if (this.userInfo.flag === "update") {
        this.orderClothes = this.userInfo;
        this.orderNumber = this.userInfo.orderNumber;
        this.currentTab = "brand";
        window.localStorage.setItem("imageObj", JSON.stringify({}));
        this.orderClothes.selectedClothes = this.orderClothes.selectedClothes?.map(
            (item, index) => {
              item.id = Date.now() + index;
              this.imageObj["image" + item.id] = item.photos?.map((item2) => {
                return {
                  url: item2,
                };
              });
              return item;
            }
        );
        window.localStorage.setItem("imageObj", JSON.stringify(this.imageObj));
        this.id = this.orderClothes.selectedClothes[0].id;
      }
      // 快速收衣
      if (this.userInfo.flag === "sorting") {
        this.orderNumber = this.userInfo.orderNumber;
        this.orderClothes = this.userInfo;
        this.orderClothes.userId =
            this.userInfo.userId === null ? "0" : this.userInfo.userId;
        window.localStorage.setItem("imageObj", JSON.stringify({}));
        this.orderClothes.selectedClothes = this.orderClothes.selectedClothes?.map(
            (item, index) => {
              item.id = Date.now() + index;
              this.imageObj["image" + item.id] = item.photos?.map((item2) => {
                return {
                  url: item2,
                };
              });
              return item;
            }
        );
        window.localStorage.setItem("imageObj", JSON.stringify(this.imageObj));
        this.id = this.orderClothes.selectedClothes[0].id;
        // 自助下单
        if (this.userInfo.oneClickOrder === 1) {
          this.currentTab = "brand";
        }
      }
    }

    // 草稿箱
    if (this.$route.params.data) {
      this.getClothesCategoryList();
      this.userInfo = this.orderClothes = JSON.parse(this.$route.params.data);
      this.userInfo.code = "caogao";
      this.pay_ways = [
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {name: "现金", ico: "现", label: 4, bool: false},
        {name: "收钱吧", ico: "收", label: 6, bool: false},
        {name: "洗后付", ico: "后", label: 5, bool: false},
      ];
      this.orderNumber = this.userInfo.orderNumber;
      window.localStorage.setItem("imageObj", JSON.stringify({}));
      this.orderClothes.selectedClothes = this.orderClothes.selectedClothes?.map(
          (item, index) => {
            this.priceAfterDiscount = this.priceAfterDiscount + item.realPrice;
            item.id = Date.now() + index;
            this.imageObj["image" + item.id] = item.photos?.map((item2) => {
              return {
                url: item2,
              };
            });
            return item;
          }
      );
      window.localStorage.setItem("imageObj", JSON.stringify(this.imageObj));
      this.id = this.orderClothes.selectedClothes[0].id;
      if (this.orderClothes.draId) {
        this.draId = this.orderClothes.draId;
      }
    }
    // 衣物id清除本地存储
    if (!this.cameraClothesId) {
      window.localStorage.setItem("imageObj", JSON.stringify({}));
    }
    // 调用是否有改价权限
    this.getStorePricingPermission();
  },
  mounted() {
    let _this = this;
    this.timer = setInterval(function () {
      _this.currentTime1 = new Date();
    }, 1000);
  },
  beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    //获取保值洗费用
    enterValueWashPrice() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const theObjectKey = this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ];
          if (this.valueWashItem.calculation) {
            this.carpetDialogVisible = true;
            this.itemClothes = this.valueWashItem;
            if (
                !Object.prototype.hasOwnProperty.call(theObjectKey, "service")
            ) {
              this.squareNum1 = "";
              this.squareNum2 = "";
              this.squareNumber = "";
            }
          } else {
            this.$set(
                theObjectKey,
                "hedgingClothesPrice",
                this.form.clothingPrices
            );
            this.$set(theObjectKey, "service", this.valueWashItem); // 重点VUE特效
            // this.orderClothes.selectedClothes[this.currentActiveSelectedClothesIndex].price = this.orderClothes.userType === 2 ? item.vipPrice : item.sellPrice;
            // 判断是否为活动价
            if (this.valueWashItem.activityFlagBool) {
              this.$set(
                  theObjectKey,
                  "realPrice",
                  this.valueWashItem.activityPrice
              );
            } else {
              this.$set(
                  theObjectKey,
                  "realPrice",
                  this.userInfo.userType === 2
                      ? this.valueWashItem.vipPrice
                      : this.valueWashItem.sellPrice
              );
            }
          }
          this.valueWashDialogVisible = false;
          this.currentTab = "brand";
        } else {
          return false;
        }
      });
    },
    changeInputNumber(val) {
      // this.form.cost = (val * (this.valueWashItem.vipPrice*100))/10000
      if (this.userInfo.userType == "1") {
        this.form.cost = Math.round(val * (this.valueWashItem.sellPrice / 100));
      } else {
        this.form.cost = Math.round(val * (this.valueWashItem.vipPrice / 100));
      }
    },
    async getStorePricingPermission() {
      let res = await getStorePricingPermission();
      this.pricingPermission = res.data.data;
    },
    // 开单备注
    remarksFun(index) {
      this.currentActiveSelectedClothesIndex = index;
      // 判断是否选择了服务
      let newData = this.orderClothes.selectedClothes[index];
      if (!newData.hasOwnProperty("service")) {
        this.$message.error(
            "当前衣物: " + newData.clothesName + " 未选择洗涤服务"
        );
        return;
      }
      this.remark = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].remark;
      this.remarksDialogVisible = true;
    },
    //关闭优惠券列表
    clonesCoupon() {
      this.couponActive = false;
    },
    // 显示优惠券
    showCouponList() {
      if (this.couponList.legth == 0) return false;
      this.couponActive = true;
      this.radioValue = false;
    },
    remarksDialogFun() {
      this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].remark = this.remark;
      this.remark = "";
      this.remarksDialogVisible = false;
    },
    //获取团购套餐衣物类型
    packageClothingType(packageId) {
      this.getLoading = true;
      listQuery(packageId)
          .then((response) => {
            this.categoryList = response.data.data;
            this.getLoading = false;
            this.changeTypes(0);
          })
          .catch(() => {
            this.getLoading = false;
          });
    },
    //活动详情
    getActiveByStore() {
      getActiveByStore().then((res) => {
        this.activities = res.data.data;
      });
    },
    // 获取全部衣物类型列表
    getClothesCategoryList: function () {
      this.getLoading = true;
      getClothesInfoList()
          .then((response) => {
            this.categoryList = response.data.data;
            this.getLoading = false;
            this.changeTypes(0);
          })
          .catch(() => {
            this.getLoading = false;
          });
    },
    // 选择衣物类型查询对应的衣物信息
    changeTypes(index) {
      this.currentTypeId = index;
      if (index != -1) {
        console.log(this.categoryList[index])
        this.categoryId = this.categoryList[index].categoryId
        this.clothesInfoList = this.categoryList[index].clothesList;
        this.$nextTick(() => {
          this.categoryList.forEach((item, val) => {
            if (this.$refs[`leftItems${val - 1}`]) {
              this.$refs[`leftItems${val - 1}`][0].style.borderRadius = "";
            }
          });
          if (index == 0) {
            this.$refs["leftItems1"][0].style.borderRadius = "0 10px 0 0";
          } else {
            this.$refs[`leftItems${index - 1}`][0].style.borderRadius =
                "0 0 10px 0";
            this.$refs[`leftItems${index + 1}`][0].style.borderRadius =
                "0 10px 0 0";
          }
        });
      }
    },
    selectType(item, index) {
      if (this.code == "edit") {
        let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
        if (imageObj) {
          delete imageObj["image" + this.id];
          window.localStorage.setItem("imageObj", JSON.stringify(imageObj));
        }
      }
      this.$store.commit("clothes/setClothesId", item.clothesId);
      this.cameraClothesId = item.clothesId;
      if (
          this.orderClothes.useService != undefined &&
          this.orderClothes.selectedClothes.length >=
          this.orderClothes.clothesNum &&
          this.orderClothes.packageType == ""
      ) {
        this.$message.error(
            "当前套餐数量不能超过" + this.orderClothes.clothesNum + "件"
        );
        return;
      }
      if (
          this.orderClothes.packageType != "" &&
          this.orderClothes.selectedClothes.length >= this.orderClothes.clothesNum
      ) {
        if (this.orderClothes.packageType == 1) {
          this.$message.error(
              "当前门店美团套餐数量不能超过(" +
              this.orderClothes.clothesNum +
              ")件"
          );
        } else if (this.orderClothes.packageType == 2) {
          this.$message.error(
              "当前门店自营套餐数量不能超过(" +
              this.orderClothes.clothesNum +
              ")件"
          );
        } else {
          this.$message.error(
              "当前门店抖音套餐数量不能超过(" +
              this.orderClothes.clothesNum +
              ")件"
          );
        }
        return;
      }
      this.id = Date.now();
      this.clothesInfoList[index].id = this.id;
      let val = this.clothesInfoList[index];
      /// 当选择商品  ---  洗衣液时  页面卡死
      this.clothesName = val.clothesName;
      if (val) {
        this.clothesId = val.clothesId;
      }

      if (val) {
        if (this.flag === "update") {
          const theObjectKey = this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ];
          theObjectKey.categoryId = val.categoryId;
          theObjectKey.categoryName = val.categoryName;
          theObjectKey.clothesId = val.clothesId;
          theObjectKey.clothesName = val.clothesName;
          theObjectKey.id = val.id;
          this.$delete(theObjectKey, "service", {});
          this.$delete(theObjectKey, "realPrice", "");
          this.currentTab = "service";
        } else {
          // 进行匹配衣物
          this.orderClothes.selectedClothes.unshift({
            clothesId: val.clothesId,
            categoryId: val.categoryId,
            categoryName: val.categoryName,
            clothesName: val.clothesName,
            clothesImage: val.image,
            couponId: 0,
            num: 1,
            remark: "",
            id: this.id,
          });
          this.currentTab = "service";
        }
      }
    },
    // 选择服务
    changeService(item) {
      if (item.serviceId != 13) {
        this.serviceItemValue = item
        if (item.serviceName == '清洗养护') {
          this.serviceDialogItem.title = '清洗养护'
          this.serviceDialogItem.body = '当前选择为【清洗养护】，因清洗后会做保养工序，保养后如客户要求再上色将会降低上色效果，为避免误选，请再次确认是否选择【清洗养护】'
          this.serviceDialogVisible = true
          return
        }
        if (item.serviceName == '上色养护') {
          this.serviceDialogItem.title = '上色养护'
          this.serviceDialogItem.body = '当前选择为【上色养护】，因清洗后会做上色工序，一旦上色后将无法再复原至原有风格，为避免误选，请再次确认是否选择【上色养护】'
          this.serviceDialogVisible = true
          return
        }
        // 重点VUE特效
        this.currentTab = "brand";
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        // 需要填写平方数的
        if (item.calculation) {
          this.carpetDialogVisible = true;
          this.itemClothes = item;
          if (!Object.prototype.hasOwnProperty.call(theObjectKey, "service")) {
            this.squareNum1 = "";
            this.squareNum2 = "";
            this.squareNumber = "";
          }
        } else {
          this.$set(theObjectKey, "service", item); // 重点VUE特效
          // this.orderClothes.selectedClothes[this.currentActiveSelectedClothesIndex].price = this.orderClothes.userType === 2 ? item.vipPrice : item.sellPrice;
          // 判断是否为活动价
          if (item.activityFlagBool) {
            this.$set(theObjectKey, "realPrice", item.activityPrice);
          } else {
            this.$set(
                theObjectKey,
                "realPrice",
                this.userInfo.userType === 2 ? item.vipPrice : item.sellPrice
            );

            this.$set(
                theObjectKey,
                "tempRealPrice",
                this.userInfo.userType === 2 ? item.vipPrice : item.sellPrice
            );
          }
        }
      } else {
        this.valueWashItem = item;
        this.form = {
          clothingPrices: 0,
        };
        this.form.cost = Math.round(
            (this.form.clothingPrices * item.vipPrice) / 100
        );
        this.valueWashDialogVisible = true;
      }
    },
    handleService() {
      let item = this.serviceItemValue;
      this.serviceDialogVisible = false
      // 重点VUE特效
      this.currentTab = "brand";
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      // 需要填写平方数的
      if (item.calculation) {
        this.carpetDialogVisible = true;
        this.itemClothes = item;
        if (!Object.prototype.hasOwnProperty.call(theObjectKey, "service")) {
          this.squareNum1 = "";
          this.squareNum2 = "";
          this.squareNumber = "";
        }
      } else {
        this.$set(theObjectKey, "service", item); // 重点VUE特效
        // this.orderClothes.selectedClothes[this.currentActiveSelectedClothesIndex].price = this.orderClothes.userType === 2 ? item.vipPrice : item.sellPrice;
        // 判断是否为活动价
        if (item.activityFlagBool) {
          this.$set(theObjectKey, "realPrice", item.activityPrice);
        } else {
          this.$set(
              theObjectKey,
              "realPrice",
              this.userInfo.userType === 2 ? item.vipPrice : item.sellPrice
          );
        }
      }
    },
    //平方数的计算
    numberChange() {
      this.squareNumber = (this.squareNum1 * this.squareNum2).toFixed(2);
    },
    //填写平方数
    editSquare() {
      if (this.squareNumber <= 0)
        return this.$message.warning("请填写大于0的数字");
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      let newClothesDate = this.objDeepCopy(this.itemClothes);
      // return
      this.$set(newClothesDate, "squareNum", this.squareNumber);
      this.$set(newClothesDate, "squareNum1", this.squareNum1);
      this.$set(newClothesDate, "squareNum2", this.squareNum2);

      this.$set(theObjectKey, "squareNum", this.squareNumber);
      this.$set(theObjectKey, "calculation", true);

      if (this.itemClothes.activityPrice) {
        this.$set(
            theObjectKey,
            "realPrice",
            Math.round(this.itemClothes.activityPrice * this.squareNumber)
        );

        // 临时真实价格
        this.$set(
            theObjectKey,
            "tempRealPrice",
            this.itemClothes.activityPrice
        )

        newClothesDate.sellPrice = Math.round(
            newClothesDate.sellPrice * this.squareNumber
        );
        this.$set(
            newClothesDate,
            "activityPrice",
            this.itemClothes.activityPrice
        );
      } else {
        this.$set(
            theObjectKey,
            "realPrice",
            this.userInfo.userType === 2
                ? Math.round(this.itemClothes.vipPrice * this.squareNumber)
                : Math.round(this.itemClothes.sellPrice * this.squareNumber)
        );

        // 临时真实价格
        this.$set(
            theObjectKey,
            "tempRealPrice",
            this.userInfo.userType === 2
                ? this.itemClothes.vipPrice
                : this.itemClothes.sellPrice
        );
      }
      this.$set(theObjectKey, "service", newClothesDate);
      // service.sellPrice
      // this.$set(
      //   theObjectKey,
      //   "realPrice",
      //   this.userInfo.userType === 2
      //     ? this.itemClothes.vipPrice * this.squareNumber
      //     : this.itemClothes.sellPrice * this.squareNumber
      // );

      this.squareNumber = "";
      this.squareNum1 = "";
      this.squareNum2 = "";
      this.carpetDialogVisible = false;
      this.isColor = false;
      this.currentTab = "brand";
    },
    cancel() {
      this.carpetDialogVisible = false;
      this.isColor = false;
    },
    objDeepCopy(source) {
      var sourceCopy = source instanceof Array ? [] : {};
      for (var item in source) {
        sourceCopy[item] =
            source[item] instanceof Object
                ? this.objDeepCopy(source[item])
                : source[item];
      }
      return sourceCopy;
    },
    // 选择品牌
    changeBrand(item) {
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      if (Object.prototype.hasOwnProperty.call(theObjectKey, "brand")) {
        this.$set(theObjectKey, "brand", item); // 重点VUE特效
        this.currentTab = "color";
      } else {
        this.$set(theObjectKey, "brand", item); // 重点VUE特效
        this.currentTab = "color";
      }
    },
    // 选择颜色
    selectColor(item) {
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      if (Object.prototype.hasOwnProperty.call(theObjectKey, "color")) {
        this.$set(theObjectKey, "color", item); // 重点VUE特效
        this.currentTab = "blemish";
      } else {
        this.$set(theObjectKey, "color", item); // 重点VUE特效
        this.currentTab = "blemish";
      }
    },
    // 选择瑕疵
    changeBlemish(item) {
      if (this.orderClothes.selectedClothes.length > 0) {
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        if (Object.prototype.hasOwnProperty.call(theObjectKey, "blemish")) {
          if (
              theObjectKey.blemish.filter(
                  (n) => n.blemishName === item.blemishName
              ).length > 0
          ) {
            // _.remove(theObjectKey.blemish,(n)=>n===item);
            theObjectKey.blemish = theObjectKey.blemish.filter(
                (n) => n.blemishName !== item.blemishName
            );
          } else theObjectKey.blemish.push(item);
        } else this.$set(theObjectKey, "blemish", [item]); // 重点VUE特效
      }
      this.$nextTick();
    },
    // 选择洗后效果
    changeEffect(item) {
      if (this.orderClothes.selectedClothes.length > 0) {
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        if (Object.prototype.hasOwnProperty.call(theObjectKey, "effects")) {
          if (
              theObjectKey.effects.filter((n) => n.effectId === item.effectId)
                  .length > 0
          ) {
            // _.remove(theObjectKey.blemish,(n)=>n===item);
            theObjectKey.effects = theObjectKey.effects.filter(
                (n) => n.effectId !== item.effectId
            );
          } else theObjectKey.effects.push(item);
        } else this.$set(theObjectKey, "effects", [item]);
      }
      this.$nextTick();
    },
    // 选择配饰
    changeAccessory(item) {
      if (this.orderClothes.selectedClothes.length > 0) {
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        if (Object.prototype.hasOwnProperty.call(theObjectKey, "accessory")) {
          theObjectKey.accessory.push(item);
        } else this.$set(theObjectKey, "accessory", [item]); // 重点VUE特效
      }
      this.$nextTick();
    },
    // 获取服务列表
    getServiceList() {
      this.serviceLoading = true;
      if (this.orderClothes.useService != undefined) {
        clothingIdentification(this.clothesId, this.orderClothes.useService)
            .then((res) => {
              this.serviceData = res.data.data.filter((item) => {
                if (!(item.calculation && item.serviceName == "保值洗")) {
                  return item;
                }
              });
              this.serviceLoading = false;
            })
            .catch(() => {
              this.serviceLoading = false;
            });
      } else {
        getClothesServiceList(this.orderClothes.userId, this.clothesId)
            .then((response) => {
              this.serviceData = response.data.data.filter((item) => {
                if (!(item.calculation && item.serviceName == "保值洗")) {
                  return item;
                }
              });
              this.serviceLoading = false;
            })
            .catch(() => {
              this.serviceLoading = false;
            });
      }
    },
    // 去服务
    goService(index) {
      if (!this.userInfo.flag) {
        this.currentActiveSelectedClothesIndex = index;
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        this.clothesId = theObjectKey.clothesId;
        // 设置平方数
        this.squareNum1 = theObjectKey.service.squareNum1;
        this.squareNum2 = theObjectKey.service.squareNum2;
        this.squareNumber = theObjectKey.service.squareNum;
        this.currentTab = "service";
        this.getServiceList();
      }
    },
    showDialog(index) {
      this.currentActiveSelectedClothesIndex = index;
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      // this.clothesId = theObjectKey.clothesId
      this.carpetDialogVisible = true;
      this.isColor = true;
      this.squareNum1 = theObjectKey.service.squareNum1;
      this.squareNum2 = theObjectKey.service.squareNum2;
      this.squareNumber = theObjectKey.service.squareNum;
    },

    // 去品牌
    goBrand(index) {
      this.currentActiveSelectedClothesIndex = index;
      this.currentTab = "brand";
    },
    // 去颜色
    goColor() {
      this.currentTab = "color";
    },
    goBlemish() {
      this.currentTab = "blemish";
    },
    goEffects(index) {
      this.currentActiveSelectedClothesIndex = index;
      this.currentTab = "effects";
      const theObjectKey = this.orderClothes.selectedClothes[this.currentActiveSelectedClothesIndex];
      this.categoryId = theObjectKey.categoryId
    },
    goAccessory() {
      this.currentTab = "accessory";
    },
    // 选择衣物
    activateSelectedClothes(item, index) {
      this.cameraClothesId = item.clothesId;
      this.clothesId = item.clothesId;
      this.code = "edit";
      ///更改衣服
      const theObjectKey = this.orderClothes.selectedClothes[index];
      this.id = theObjectKey.id;
      this.$message.success("当前操作衣物:" + theObjectKey.clothesName);
      if (!this.userInfo.flag) {
        this.flag = "update";
        this.currentActiveSelectedClothesIndex = index;
        if (this.orderClothes.selectedClothes.length > 0) {
          const theObjectKey = this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ];
          if (Object.prototype.hasOwnProperty.call(theObjectKey, "photos")) {
            this.$refs.componentCamera.imgSrc = this.orderClothes.selectedClothes[
                this.currentActiveSelectedClothesIndex
                ].photos;
          }
          const index = this.categoryList.findIndex(
              (category) => category.categoryName === theObjectKey.categoryName
          );
          this.changeTypes(index);
          this.clothesName = theObjectKey.clothesName;
        }
        this.currentTab = "cloth";
      } else {
        this.currentActiveSelectedClothesIndex = index;
        if (this.userInfo.flag === "update") {
          this.currentTab = "brand";
        } else {
          if (this.userInfo.oneClickOrder === 2) {
            this.flag = "update";
            const theObjectKey = this.orderClothes.selectedClothes[
                this.currentActiveSelectedClothesIndex
                ];
            this.currentTab = "cloth";
          } else {
            this.currentActiveSelectedClothesIndex = index;
            this.currentTab = "brand";
          }
        }
      }
      if (Object.prototype.hasOwnProperty.call(theObjectKey, "service")) {
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        this.squareNum1 = theObjectKey.service.squareNum1;
        this.squareNum2 = theObjectKey.service.squareNum2;
        this.squareNumber = theObjectKey.service.squareNum;
      }
    },
    // 添加品牌
    handlerAddBrand() {
      this.$prompt("请输入品牌名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\\]{0,50}$/,
        inputErrorMessage: "输入内容不能超过50个字符！",
      })
          .then(({value}) => {
            var newData = this.copyBrandData.find(
                (item) => item.brandName === value
            ); //返回对象
            if (newData) {
              this.changeBrand(newData);
              this.$message.success("自动匹配成功");
              return;
            }
            let data = {
              brandName: value,
            };
            this.changeBrand(data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
    },
    // 添加颜色
    handlerAddColor() {
      this.$prompt("请输入颜色名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\\]{0,50}$/,
        inputErrorMessage: "输入内容不能超过50个字符！",
      })
          .then(({value}) => {
            if (value.trim() == "")
              return this.$message.warning("请输入颜色名称");
            var newData = this.copyColorData.find(
                (item) => item.colorName === value
            ); //返回对象
            if (newData) {
              this.selectColor(newData);
              this.$message.success("自动匹配成功");
              return;
            }
            let data = {
              colorName: value,
            };
            this.selectColor(data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
    },
    //添加瑕疵
    handlerAddBlemish() {
      this.$prompt("请输入瑕疵名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\\]{0,100}$/,
        inputErrorMessage: "输入内容不能超过100个字符！",
      })
          .then(({value}) => {
            if (value.trim() == "")
              return this.$message.warning("请输入瑕疵名称");
            // 先搜索下是否存在 存在的话自动选择
            var newData = this.copyBlemishData.find(
                (item) => item.blemishName === value
            ); //返回对象
            if (newData) {
              this.changeBlemish(newData);
              this.$message.success("自动匹配成功");
              return;
            }
            // 没有的话 添加成功后自动选择
            let data = {
              blemishName: value,
              isSerious: 3,
            };
            this.changeBlemish(data);
          });
    },
    //添加洗后风险
    handlerAddEffect() {
      this.$prompt("请输入洗后风险名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\\]{0,100}$/,
        inputErrorMessage: "输入内容不能超过100个字符！",
      })
          .then(({value}) => {
            if (value.trim() == "")
              return this.$message.warning("请输入洗后风险名称");
            // 先搜索下是否存在 存在的话自动选择
            var newData = this.copyEffectsData.find(
                (item) => item.effectName === value
            ); //返回对象
            if (newData) {
              this.changeEffect(newData);
              this.$message.success("自动匹配成功");
              return;
            }
            // 没有的话 添加成功后自动选择
            let data = {
              effectName: value,
              isSerious: 3,
            };
            this.changeEffect(data);
          })
    },
    // 删除瑕疵
    deleteBlemishItem(index) {
      this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].blemish.splice(index, 1);
    },
    deleteItemColor(item) {
      if (
          item.color.colorName ==
          this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].color.colorName
      ) {
        this.$delete(
            this.orderClothes.selectedClothes[
                this.currentActiveSelectedClothesIndex
                ],
            "color",
            item.color
        );
      }
    },
    deleteItemBrand(item) {
      if (
          item.brand.brandName ==
          this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].brand.brandName
      ) {
        this.$delete(
            this.orderClothes.selectedClothes[
                this.currentActiveSelectedClothesIndex
                ],
            "brand",
            item.brand
        );
      }
    },
    // 首字母搜索颜色
    pinyinMatch(val) {
      if (val) {
        let result = [];
        this.copyColorData.forEach((e) => {
          let m = pinyin.match(e.colorName, val);
          if (m) {
            result.push(e);
          }
        });
        this.colorData = result; //返回匹配的数组
      } else {
        this.colorData = [];
      }
    },
    // 搜索品牌
    searchBrand() {
      //如果没有输入内容，不让往下执行
      if (this.text === "") {
        this.brandData = this.copyBrandData;
        return;
      }
      let resultList = [];
      this.copyBrandData.forEach((item) => {
        if (item.brandName.indexOf(this.text) > -1) {
          resultList.push(item);
        }
      });
      this.brandData = resultList;
    },
    // 首字母搜索品牌
    pinSearchBrand(val) {
      let result = [];
      this.copyBrandData.forEach((e) => {
        let m = pinyin.match(e.brandName, val);
        if (m) {
          result.push(e);
        }
      });
      this.brandData = result; //返回匹配的数组
    },
    // 搜索颜色
    searchColor() {
      //如果没有输入内容，不让往下执行
      if (this.text === "") {
        this.colorData = this.copyColorData;
        return;
      }
      let resultList = [];
      this.colorData.forEach((item) => {
        if (item.colorName.indexOf(this.text) > -1) {
          resultList.push(item);
        }
      });
      this.colorData = resultList;
    },
    // 搜索瑕疵
    searchBlemish() {
      //如果没有输入内容，不让往下执行
      if (this.text === "") {
        this.blemishData = this.copyBlemishData;
        return;
      }
      let resultList = [];
      this.blemishData.forEach((item) => {
        if (item.blemishName.indexOf(this.text) > -1) {
          resultList.push(item);
        }
      });
      this.blemishData = resultList;
    },
    // 首字母搜索瑕疵
    pinSearchBlemish(val) {
      let result = [];
      this.copyBlemishData.forEach((e) => {
        let m = pinyin.match(e.blemishName, val);
        if (m) {
          result.push(e);
        }
      });
      this.blemishData = result; //返回匹配的数组
    },
    // 搜索瑕疵
    searchAccessory() {
      //如果没有输入内容，不让往下执行
      if (this.text === "") {
        this.accessoryData = this.copyAccessoryData;
        return;
      }
      let resultList = [];
      this.accessoryData.forEach((item) => {
        if (item.accessoryName.indexOf(this.text) > -1) {
          resultList.push(item);
        }
      });
      this.accessoryData = resultList;
    },
    // 搜索瑕疵
    searchEffects() {
      //如果没有输入内容，不让往下执行
      if (this.text === "") {
        this.effectData = this.copyEffectsData;
        return;
      }
      let resultList = [];
      this.copyEffectsData.forEach((item) => {
        if (item.effectName.indexOf(this.text) > -1) {
          resultList.push(item);
        }
      });
      this.effectData = resultList;
    },
    // 首字母搜索瑕疵
    pinSearchEffects(val) {
      let result = [];
      this.copyEffectsData.forEach((e) => {
        let m = pinyin.match(e.effectName, val);
        if (m) {
          result.push(e);
        }
      });
      this.effectData = result; //返回匹配的数组
    },
    // 删除
    deleteSelectedClothesItem(i) {
      this.$confirm("确定删除衣物, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.orderClothes.selectedClothes.splice(i, 1);
            if (this.orderClothes.selectedClothes.length === 0) {
              this.flag = "";
              this.clothesName = "";
              this.currentTab = "cloth";
            }
            this.editView = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 继续收衣
    goOnClothes() {
      if (
          this.orderClothes.flag === "sorting" &&
          this.orderClothes.oneClickOrder === 1
      ) {
        this.$confirm("确定继续收衣, 是否继续?", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.flag = "";
          this.currentActiveSelectedClothesIndex = 0;
          this.clothesName = "";
          this.currentTab = "cloth";
          this.$message.success("切换衣物界面成功");
        });
        return;
      }
      const theObjectKey = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ];
      if (theObjectKey.service) {
        this.$message.success("切换衣物界面成功");
      }
      this.flag = "";
      this.clothesName = "";
      this.currentTab = "cloth";
      this.code = "";
    },
    // 收银台
    async handleDetermine() {
      let _this = this;
      _this.settlementDialogVisible = true;
      if (this.orderClothes.selectedClothes.length <= 0) {
        this.$message.error("请先选择衣物");
        _this.settlementDialogVisible = false;
        return;
      }
      // 判断衣物是否选择洗涤服务
      let newData = this.orderClothes.selectedClothes.filter(
          (item) => !item.hasOwnProperty("service")
      );
      if (newData.length > 0) {
        let clothesName = newData.map((item) => item.clothesName).join("、");
        this.$message.error("当前衣物: " + clothesName + " 未选择洗涤服务");
        _this.settlementDialogVisible = false;
        return;
      }
      //   if (this.isCamera) {
      //     this.$nextTick(() => {
      //       _this.$refs.componentCamera.stopNavigator(); //关闭摄像头
      //     });
      //   }
      // 进行赋值操作
      this.selectedClothes = this.orderClothes.selectedClothes;
      for (let i = 0; i < this.orderClothes.selectedClothes.length; i++) {
        this.orderClothes.selectedClothes[i].couponId = 0;
      }
      // 判断图片是否更新
      let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
      let inconformityBool = this.selectedClothes.every((item) => {
        if (item.photos?.length == imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      let inconformityArr = this.selectedClothes.filter((item) => {
        if (item.photos?.length != imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      if (!inconformityBool) {
        let str = inconformityArr.map((item) => item.clothesName).join("、");
        this.$message.error(str + "图片不一致，请刷新图片");
        _this.settlementDialogVisible = false;
        return;
      }
      try {
        let userId =
            this.userInfo.userId && this.userInfo.userId != 0
                ? this.userInfo.userId
                : 0;
        const {data: res} = await checkOrderClothesInfo(
            userId,
            this.orderClothes
        );
        if (res.code == 0) {
          _this.settlementDialogVisible = false;
          this.payVisible = true;
          this.isCamera = false;
          // 提成
          this.orderCommission(res.data.clothesCommissionList);
          // 用户账户信息
          if (res.data.userAccount) {
            this.userAccount = res.data.userAccount;
            this.pay_ways = this.pay_ways.map((item) => {
              if (item.name == "通享卡余额") {
                if (
                    this.userAccount.universalBalance == null ||
                    this.userAccount.universalBalance == 0
                ) {
                  item.bool = true;
                  return item;
                } else {
                  item.bool = false;
                }
                if (this.userAccount.balance > 0) {
                  item.bool = true;
                } else {
                  item.bool = false;
                }
              }
              if (item.name == "历史余额") {
                if (this.userAccount.balance == null) {
                  item.bool = true;
                  return item;
                } else {
                  item.bool = false;
                }
                if (this.userAccount.balance == 0) {
                  item.bool = true;
                } else {
                  item.bool = false;
                }
              }
              if (item.name == "企业余额") {
                if (this.userAccount.enterpriseAvailableBalance == null) {
                  item.bool = true;
                  return item;
                } else {
                  item.bool = false;
                }
                if (this.userAccount.enterpriseAvailableBalance == 0) {
                  item.bool = true;
                } else {
                  item.bool = false;
                }
              }
              return item;
            });
          } else {
            this.pay_ways = this.pay_ways.map((item) => {
              if (item.name == "通享卡余额") {
                item.bool = true;
              } else if (item.name == "历史余额") {
                item.bool = true;
              } else if (item.name == "企业余额") {
                item.bool = true;
              } else {
                item.bool = false;
              }
              return item;
            });
          }
          // 优惠券
          this.canUseCouponList(res.data.useCouponList);
          // 结算信息
          this.settlementInfo = res.data.settlementInfo;
          // 实付金额
          this.priceAfterDiscount = res.data.settlementInfo.actuallyPaidPrice;
        } else {
          _this.settlementDialogVisible = false;
          this.$message.error(res.msg);
        }
      } catch (e) {
        _this.settlementDialogVisible = false;
      }
    },
    // 跟新历史余额
    async getcheckOrderClothesInfo() {
      let userId =
          this.userInfo.userId && this.userInfo.userId != 0
              ? this.userInfo.userId
              : 0;
      const {data: res} = await checkOrderClothesInfo(
          userId,
          this.orderClothes
      );
      if (res.code == 0) {
        if (res.data.userAccount) {
          this.userAccount = res.data.userAccount;
          this.pay_ways = this.pay_ways.map((item) => {
            if (item.name == "通享卡余额") {
              if (
                  this.userAccount.universalBalance == null ||
                  this.userAccount.universalBalance == 0
              ) {
                item.bool = true;
                return item;
              } else {
                item.bool = false;
              }
              if (this.userAccount.balance > 0) {
                item.bool = true;
              } else {
                item.bool = false;
              }
            }
            if (item.name == "历史余额") {
              if (this.userAccount.balance == null) {
                item.bool = true;
                return item;
              } else {
                item.bool = false;
              }
              if (this.userAccount.balance == 0) {
                item.bool = true;
              } else {
                item.bool = false;
              }
            }
            return item;
          });
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //订单提成金额
    orderCommission(data) {
      if (data.length > 0) {
        this.commissionPrice = data;
        this.commissionPrice.forEach((item) => {
          this.selectedClothes.forEach((item1) => {
            if (
                item.clothesId == item1.clothesId &&
                item.serviceId == item1.service.serviceId
            ) {
              this.$set(item1, "commissionPrice", item.commissionPrice);
            }
          });
        });
      } else {
        this.commissionPrice = [];
      }
    },

    // 保存到草稿箱
    handleAddDraftBox() {
      let _this = this;
      if (
          this.orderClothes.useService != "" &&
          this.orderClothes.useService != undefined
      ) {
        return _this.$router.go(-1);
      }
      if (
          _this.userInfo.flag === "update" ||
          _this.userInfo.flag === "sorting"
      ) {
        this.$confirm("确定离开收衣页面?数据将不保存到草稿箱", "提示", {
          confirmButtonText: "确认离开",
          cancelButtonText: "取消离开",
          type: "warning",
        })
            .then(() => {
              _this.$router.push("/home/get");
            })
            .catch(() => {
              _this.$message("取消离开");
            });
        return;
      }
      this.$confirm("正在进行收衣操作,确定离开后衣物将保存至草稿箱?", "提示", {
        confirmButtonText: "确认保存",
        cancelButtonText: "取消保存",
        type: "warning",
      })
          .then(() => {
            if (this.orderClothes.selectedClothes.length <= 0) {
              _this.$message.error("当前没有衣物要进行保存");
              _this.$router.go(-1);
              return;
            }
            let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
            let inconformityBool = this.orderClothes.selectedClothes.every(
                (item) => {
                  if (item.photos?.length == imageObj["image" + item.id]?.length) {
                    return item;
                  }
                }
            );
            let inconformityArr = this.orderClothes.selectedClothes.filter(
                (item) => {
                  if (item.photos?.length != imageObj["image" + item.id]?.length) {
                    return item;
                  }
                }
            );
            if (!inconformityBool) {
              let str = inconformityArr
                  .map((item) => item.clothesName)
                  .join("、");
              this.$message.error(str + "图片不一致，请刷新图片保存至草稿箱");
              return;
            }
            // 调用接口进行保存
            let data = {
              realName: this.orderClothes.name,
              phone: this.orderClothes.phone,
              orderDto: this.orderClothes,
            };

            addDraftbox(data).then(() => {
              _this.$message.success("保存成功");
              _this.$router.go(-1);
            });
          })
          .catch(() => {
            _this.$router.go(-1);
            // _this.$router.push('/home/get')
          });
    },
    // 删除草稿箱
    deleteClothesDraftboxs(id) {
      deleteClothesDraftboxs(id).then(() => {
      });
    },
    // 选择支付方式
    choosePayWay(item, index) {
      // 只能现金结账
      if (Number(this.actuallyPrice) === 0 && item.label !== 4) {
        this.$message.error("支付金额为0只能选择现金结账哦!");
        return false;
      }

      // 企业余额 重新计算
      // if (this.orderClothes.payWay === 13) {
      //   this.handleDetermine()
      // }
      this.choosePaywayIndex = index;
      this.payWayName = item.name;
      this.orderClothes.payWay = item.label;

      // 如果选择了余额或者洗后付或现金 不进行弹框 收钱吧弹框
      if (this.orderClothes.payWay === 6) {
        if (this.choosePaywayIndex !== 0) this.payWay = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }

      // this.handleDetermine()

      // 如果选择了余额或者洗后付或现金 不进行弹框 美团弹框
      // if (this.orderClothes.payWay === 7) {
      //   if (this.choosePaywayIndex !== 0) this.dialogMeituanCousume = true
      //   this.$nextTick(() => {
      //     this.$refs.receiptCode.focus()
      //   })
      // }
    },

    // 查询美团券信息
    async scanCodeCoupon() {
      let res = await scanCodeCoupon(this.receiptCode);
      if (res.data.code == 0) {
        // 组装数据
        this.receiptInfo = res.data.data[0];
      } else {
        return this.$message.error(res.data.msg);
      }
    },

    // 取消消费
    handleCancelCousume() {
      this.receiptInfo = {
        appDealId: "",
        count: 0,
        dealPrice: "",
        dealTitle: "",
        receiptCode: "",
      };
      this.orderClothes.receiptNum = 0;
    },

    // 清除支付方式
    cleanPayWay() {
      this.choosePaywayIndex = -1;
      this.payWayName = "";
      this.orderClothes.payWay = "";
    },
    // 选中衣物
    close() {
      this.selectCouponActive = "";
      this.choosePaywayIndex = "";
      this.orderClothes.payWay = "";
      this.couponId = 0;
      // 恢复优惠券的使用
      this.clearCoupon();
      // 隐藏支付弹框
      this.payVisible = false;
    },
    // 优惠券
    canUseCouponList(data) {
      this.couponList = data.map((item) => {
        item.radioValue = false;
        item.ruleShow = false;
        return item;
      });
      if (this.couponList.length > 0) {
        this.couponPlaceholder = "有" + this.couponList.length + "张优惠券可用";
      } else {
        this.couponPlaceholder = "暂无优惠券可用";
      }
    },
    // 使用优惠券
    useCoupon(res, val) {
      this.selectCouponActive = res;
      // 先清除支付方式
      this.cleanPayWay();
      let _this = this;
      if (res) {
        let data = {
          selectedClothes: _this.orderClothes.selectedClothes,
          alreadyCouponList: _this.chooseCouponList,
          actuallyPrice: this.actuallyPrice,
        };
        useCoupon(this.userInfo.userId, res, data).then((response) => {
          if (response.data.code == 0) {
            this.radioValue = false;
            this.couponList = this.couponList.map((item, index) => {
              if (index == val) {
                item.radioValue = true;
              }
              return item;
            });
            let price =
                this.settlementInfo.clothesOriginalPrice -
                this.settlementInfo.benefitsDiscountPrice;
            // settlementInfo.clothesOriginalPrice
            // settlementInfo.benefitsDiscountPrice
            let couponObj = response.data.data;
            // this.clothes.price = response.data.data.amountAfterDiscount
            _this.couponAmount =
                couponObj.totalCouponAmount > price
                    ? price
                    : couponObj.totalCouponAmount;
            _this.couponName = couponObj.couponName;
            let couponId = couponObj.couponId;
            let item = this.couponList.find((item) => item.id == couponId);
            couponObj.money = item.money;
            let couponType = couponObj.couponType;
            // 1：免单券、2:免洗券、3：满减券 4 打折 5. 现金券
            if (couponType === 2) {
              let clothesId = couponObj.clothesId;
              // 衣物的
              let newArr = JSON.parse(JSON.stringify(_this.selectedClothes));
              var index1 = newArr.findIndex(function (item) {
                return (
                    item.clothesId === clothesId &&
                    Number(item.realPrice) === Number(_this.couponAmount)
                ); //如果返回true，那么findIndex方法会将这个item对应的id返回到外面接受
              });
              newArr.map((cl, index) => {
                if (index === index1) {
                  cl.couponId = couponId;
                }
                return cl;
              });
              _this.selectedClothes = newArr;
            } else {
              // 订单里 满减券
              let newOrderClothes = JSON.parse(
                  JSON.stringify(_this.orderClothes)
              );
              _this.orderClothes = newOrderClothes;
            }
            if (this.actuallyPrice == "0.00") {
              this.payWay = false;
            }
            // 添加到当前选择优惠券数组
            _this.addChooseCoupon(couponObj);
          } else {
            this.$message.error(response.data.msg);
          }
        });
      }
    },
    // 添加到当前选择优惠券数组
    addChooseCoupon(obj) {
      let _this = this;
      //find方法查询是否存在相同的数据
      let isAdd = _this.chooseCouponList.find((item) => {
        if (item.couponId == obj.couponId) {
          return true;
        }
      });
      if (typeof isAdd == "undefined") {
        _this.chooseCouponList.push({
          amountAfterDiscount: obj.amountAfterDiscount,
          couponAmount: obj.couponAmount,
          couponId: obj.couponId,
          couponType: obj.couponType,
          money: Number(obj.money),
        });
      }
    },
    // 清除优惠券
    clearCoupon() {
      this.valDiscount = "";
      this.couponAmount = 0;
      this.couponName = "";
      this.selectCouponActive = "";
      // 进行赋值操作
      this.selectedClothes = this.orderClothes.selectedClothes.map((item) => {
        item.couponId = 0;
        return item;
      });
      this.chooseCouponList = [];
    },
    // 支付按钮
    payBtn() {
      if (!this.orderClothes.payWay) {
        this.$message.error("请选择支付方式!");
        return false;
      }
      // 如果选择了余额或者洗后付或现金 不进行弹框
      if (this.orderClothes.payWay === 6) {
        if (this.choosePaywayIndex !== 0) this.payWay = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
          //  if (this.orderClothes.payWay === 7) {
          //   if (this.choosePaywayIndex !== 0) this.dialogMeituanCousume = true
          //   this.$nextTick(() => {
          //     this.$refs.receiptCode.focus()
          //   })
      // }
      else {
        //历史余额
        if (this.orderClothes.payWay === 3) {
          // 付款金额与余额比较
          if (this.userAccount.balance >= this.actuallyPrice) {
            this.payBtnLoading = true;
            this.addOrder();
          } else {
            this.$message.error("余额不足无法支付");
            this.transferBalanceShow = false;
            return false;
          }
        }
        //通享卡余额
        if (this.orderClothes.payWay === 11) {
          // 付款金额与余额比较
          if (this.userAccount.universalBalance >= this.actuallyPrice) {
            this.payBtnLoading = true;
            this.addOrder();
          } else {
            this.$message.error("通享卡余额不足无法支付");
            return false;
          }
        }
        //通享卡余额
        if (this.orderClothes.payWay === 13) {
          // 付款金额与余额比较
          if (this.userAccount.enterpriseAvailableBalance >= this.actuallyPrice) {
            this.payBtnLoading = true;
            this.addOrder();
          } else {
            this.$message.error("企业余额不足无法支付");
            return false;
          }
        }
        if (
            this.orderClothes.payWay === 4 ||
            this.orderClothes.payWay === 5 ||
            this.orderClothes.payWay === 7 ||
            this.orderClothes.payWay === 8 ||
            this.orderClothes.payWay === 9 ||
            this.orderClothes.payWay === 10
        ) {
          this.payBtnLoading = true;
          this.addOrder();
        }
      }
      // this.payBtnLoading = false
    },
    // 提交订单
    submit() {
      if (this.actuallyPrice == "0.00") {
        return;
      }
      this.qrCodePayView = true;
      if (this.payVal) {
        this.orderClothes.orderSource = "1";
        this.orderClothes.authCode = this.payVal;
        this.orderClothes.oneClickOrder = "1";
        this.orderClothes.selectedClothes = this.selectedClothes;
        this.orderClothes.payWay = 6;
        this.orderClothes.couponList =
            this.chooseCouponList.length > 0
                ? this.chooseCouponList.map((x) => {
                  return {
                    couponId: x.couponId,
                    couponType: x.couponType,
                  };
                })
                : [];
        addOrder(this.orderClothes).then((response) => {
          if (response.data.code === 0) {
            this.qrCodePayViewName = "订单创建成功";
            if (response.data.data) {
              this.orderNumber = response.data.data.orderNumber;
              this.outTradeNo = response.data.data.outTradeNo;
              // 调用支付接口
              // this.pay()
              this.payOrder();
            }
          } else {
            this.$message({
              type: "error",
              message: response.data.msg,
            });
          }
        });
      } else {
        this.qrCodePayView = false;
        this.$message({
          type: "error",
          message: "请读入或输入付款码",
        });
      }
    },

    // 美团提交订单
    meituanSubmit() {
      this.receiptCodePayView = true;
      if (this.receiptInfo.receiptCode) {
        this.orderClothes.orderSource = "1";
        // this.payVal = this.orderClothes.authCode = this.receiptInfo.receiptCode
        this.payVal = this.orderClothes.receiptCode;
        this.orderClothes.oneClickOrder = "1";
        this.orderClothes.selectedClothes = this.selectedClothes;
        addOrder(this.orderClothes)
            .then((response) => {
              if (response.data.code === 0) {
                this.receiptCodePayViewName = "订单创建成功";
                if (response.data.data) {
                  this.orderNumber = response.data.data.orderNumber;
                  this.outTradeNo = response.data.data.outTradeNo;
                  // 调用支付接口
                  this.payOrder();
                }
              } else {
                this.$message.error(response.data.msg);
              }
            })
            .catch((error) => {
              console.error(error);
              this.receiptCodePayView = false;
            });
      }
    },

    // 创建订单
    addOrder() {
      // 创建订单
      this.orderClothes.orderSource = "1";
      if (this.orderClothes.payWay === 7 || this.orderClothes.payWay === 10) {
        this.payVal = this.orderClothes.receiptCode;
      } else {
        this.orderClothes.authCode = this.payVal;
      }
      this.orderClothes.oneClickOrder = "1";
      if (this.settlementInfo) {
        this.orderClothes.activityIdList = this.settlementInfo.activityIdList;
      }
      this.orderClothes.selectedClothes = this.selectedClothes;
      this.orderClothes.couponList =
          this.chooseCouponList.length > 0
              ? this.chooseCouponList.map((x) => {
                return {
                  couponId: x.couponId,
                  couponType: x.couponType,
                };
              })
              : [];

      addOrder(this.orderClothes)
          .then((response) => {
            if (response.data.code === 0) {
              this.payBtnLoadingVal = "订单创建成功";
              if (response.data.data) {
                this.orderNumber = response.data.data.orderNumber;
                this.outTradeNo = response.data.data.outTradeNo;
                // 调用支付接口
                this.payOrder();
              }
            } else {
              this.payBtnLoading = false;
              this.$message({
                type: "error",
                message: response.data.msg,
              });
            }
          })
          .catch(() => {
            this.payBtnLoading = false;
          });
    },
    // 支付订单
    payOrder() {
      let payWay = this.orderClothes.payWay;
      let orderNumber = this.orderNumber;
      let authCode = this.payVal;
      let data = {
        payWay,
        orderNumber,
        authCode,
      };
      payOrder(data)
          .then((response) => {
            if (response.data.code === 0) {
              // 只有收钱吧有回调
              if (this.orderClothes.payWay === 6) {
                this.outTradeNo = response.data.data.outTradeNo;
                this.queryPayResult(this.outTradeNo); //调用接口的方法
                this.myInterval = setInterval(() => {
                  this.queryPayResult(this.outTradeNo); //调用接口的方法
                }, 3000);
              } else {
                this.commonPaySuccess();
              }
            } else {
              if (this.orderClothes.payWay === 6) {
                clearInterval(this.shouInterval);
                // 需要用户支付
                if (response.data.data.errCode === "USERPAYING") {
                  this.outTradeNo = response.data.data.outTradeNo;
                  this.myInterval = setInterval(() => {
                    this.queryPayResult(this.outTradeNo); //调用接口的方法
                  }, 3000);
                } else {
                  this.payBtnLoading = false;
                  this.$message.error(response.data.msg);
                  this.closePayModal();
                }
              } else {
                this.$message.error(response.data.msg);
                this.closePayModal();
              }
            }
          })
          .catch((reason) => {
            // 要进行跳转到当前未支付的订单
            this.closePayModal();
          });
      if (this.orderClothes.payWay === 6) {
        this.payBtnLoadingVal = "等待用户输入密码!";
      }
    },
    // 查询支付结果
    queryPayResult(outTradeNo) {
      if (this.orderClothes.payWay === 6) {
        clearInterval(this.shouInterval);
      }
      const params = new URLSearchParams();
      params.append("payWay", this.orderClothes.payWay);
      params.append("outTradeNo", outTradeNo);
      params.append("orderNumber", this.orderNumber);
      payOrderQuery(params).then((response) => {
        // 微信
        if (response.data.data.success) {
          if (response.data.data.tradeState === "SUCCESS") {
            this.commonPaySuccess();
          } else if (response.data.data.tradeState === "PAYERROR") {
            this.payBtnLoading = false;
            this.$message.error(response.data.data.msg);
            this.closePayModal();
          } else {
            this.payBtnLoadingVal = "等待用户输入密码!";
          }
        } else {
          this.payBtnLoading = false;
          this.$message.error(response.data.data.msg);
          this.closePayModal();
        }
      });
    },
    // 关闭支付框
    closePayModal() {
      if (this.orderNumber) {
        clearInterval(this.myInterval);
        clearInterval(this.shouInterval);
        this.$router.push({
          path: "/home/order",
          query: {
            orderNumber: this.orderNumber,
          },
        });
      }
    },
    // 公共的
    commonPaySuccess() {
      clearInterval(this.myInterval);
      if (this.draId) {
        this.deleteClothesDraftboxs(this.draId);
      }
      this.settleAccountsDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.PaySuccess.orderNumber = this.orderNumber;
        this.$refs.PaySuccess.commissionPrice = this.settlementInfo.expectedTotalCommissionPrice;
      });
      this.payBtnLoading = false;
    },
    // 选择拍照
    changePhotos(id) {
      this.$nextTick(() => {
        let photos = this.$refs.componentCamera.imgSrc;
        const theObjectKey = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ];
        if (theObjectKey.id == id) {
          this.$set(theObjectKey, "photos", photos);
        }
      });
    },
    // 打开价格弹框
    openEditModal(index) {
      // 先判断是否选择了服务
      this.currentActiveSelectedClothesIndex = index;
      let newData = this.orderClothes.selectedClothes[index];
      if (!newData.hasOwnProperty("service")) {
        this.$message.error(
            "当前衣物: " + newData.clothesName + " 未选择洗涤服务"
        );
        return;
      }
      // 价格
      let userType = this.userInfo.userType;
      // 散客
      if (userType == 1) {
        this.originalPrice = this.realPrice = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ].service.sellPrice;
      } else {
        // 会员
        this.originalPrice = this.realPrice = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ].service.vipPrice;
      }
      // 修改原因
      this.modifyReason = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].modifyReason;
      // 备注
      this.remark = this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].remark;
      this.editView = true;
    },
    // 处理编辑
    handleEdit() {
      if (!this.realPrice) {
        this.$message.error("价格不能为空！");
        return;
      }
      if (Number(this.originalPrice) !== Number(this.realPrice)) {
        if (!this.modifyReason) {
          this.$message.error("价格产生变动,请填写修改价格原因");
          return;
        }

        // 临时的价格
        let tempRealPrice = this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ].tempRealPrice

        // 判断改价不能朝上
        if (Number(this.realPrice) > Number(tempRealPrice * 1.5)) {
          this.$message.error("改后价格不能超过原价/会员价");
          return;
        }
        // 价格
        let userType = this.userInfo.userType;
        // 散客
        if (userType == 1) {
          this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].realPrice = this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].service.sellPrice = this.realPrice;
        } else {
          // 会员
          this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].realPrice = this.orderClothes.selectedClothes[
              this.currentActiveSelectedClothesIndex
              ].service.vipPrice = this.realPrice;
        }
        this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ].modifyReason = this.modifyReason;
        this.orderClothes.abnormal = 2;
        this.realPrice = "";
      }
      // 备注
      this.orderClothes.selectedClothes[
          this.currentActiveSelectedClothesIndex
          ].remark = this.remark;
      this.remark = "";
      this.editView = false;
    },
    // 切换标签
    toggleTab: function (index) {
      // 修改时不能选择衣物/服务
      if (index === "cloth" || index === "service") {
        if (
            this.userInfo.flag === "update" ||
            (this.userInfo.flag === "sorting" &&
                this.userInfo.oneClickOrder === 1)
        ) {
          this.$message.error("修改订单时无法进行切换");
          return false;
        }
      }
      this.currentTab = index;
      if (index == "camera") {
        setTimeout(() => {
          this.$refs.componentCamera.refreshImage();
        }, 0);
      }
    },
    // 修改衣物
    updateClothes() {
      //判断是否选择洗涤服务
      if (this.orderClothes.selectedClothes.length <= 0) {
        this.$message.error("请选择衣物");
        return false;
      }
      this.orderClothes.orderNumber = this.orderNumber;
      this.$nextTick(() => {
        this.orderClothes.selectedClothes[
            this.currentActiveSelectedClothesIndex
            ].photos = this.$refs.componentCamera?.imgSrc;
      });
      this.orderClothes.price = this.total.priceAfterDiscount;
      let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
      let inconformityBool = this.orderClothes.selectedClothes.every((item) => {
        if (item.photos?.length == imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      let inconformityArr = this.orderClothes.selectedClothes.filter((item) => {
        if (item.photos?.length != imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      if (!inconformityBool) {
        let str = inconformityArr.map((item) => item.clothesName).join("、");
        this.$message.error(str + "图片不一致，请刷新图片");
        return;
      }
      //  对象层级太多，转换成json格式在重新赋值
      this.imageArr = JSON.stringify(this.orderClothes.selectedClothes);
      this.$confirm("确认修改订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            // 修改订单
            this.orderClothes.selectedClothes = JSON.parse(this.imageArr);
            updateOrderClothes(this.orderClothes).then((response) => {
              if (response.data.code === 0) {
                this.$message.success("修改成功");
                this.$router.push({
                  path: "/home/order",
                  query: {
                    orderNumber: this.orderClothes.orderNumber,
                  },
                });
              } else {
                this.$message({
                  type: "error",
                  message: response.data.msg,
                });
              }
            });
          })
          .catch(() => {
          });
    },
    // 确认收衣
    confirmClothes() {
      //判断是否选择洗涤服务
      if (this.orderClothes.selectedClothes.length <= 0) {
        this.$message.error("请选择衣物");
        return false;
      }
      // 判断衣物是否选择洗涤服务
      let newData = this.orderClothes.selectedClothes.filter(
          (item) => !item.hasOwnProperty("service")
      );
      if (newData.length > 0) {
        let clothesName = newData.map((item) => item.clothesName).join("、");
        this.$message.error("当前衣物: " + clothesName + " 未选择洗涤服务");
        return false;
      }
      // 判断图片是否更新
      let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
      let inconformityBool = this.orderClothes.selectedClothes.every((item) => {
        if (item.photos?.length == imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      let inconformityArr = this.orderClothes.selectedClothes.filter((item) => {
        if (item.photos?.length != imageObj["image" + item.id]?.length) {
          return item;
        }
      });
      if (!inconformityBool) {
        let str = inconformityArr.map((item) => item.clothesName).join("、");
        this.$message.error(str + "图片不一致，请刷新图片");
        this.orderSortingInfoLoading = false;
        return;
      }

      this.orderSortingInfoLoading = true;
      // 计算衣物信息
      calculateClothesInfo(this.orderClothes).then((response) => {
        this.orderSortingInfo = response.data.data;
        this.orderSortingInfoLoading = false;
      });
      this.sortingDialogVisible = true;
    },
    // 确认收衣
    handlerConfirmClothes() {
      this.orderClothes.orderNumber = this.orderNumber;
      // this.orderClothes.selectedClothes[this.currentActiveSelectedClothesIndex].photos = this.$refs.componentCamera.imgSrc;
      this.orderClothes.price = this.total.priceAfterDiscount;
      this.$confirm("确认收衣?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.orderSortingInfoLoading = true;
            // 修改订单
            updateOrder(this.orderClothes).then((response) => {
              this.orderSortingInfoLoading = false;
              if (response.data.code === 0) {
                this.$message.success("收衣成功!");
                this.$router.push({
                  path: "/home/order",
                  query: {
                    orderNumber: this.orderClothes.orderNumber,
                  },
                });
                if (this.draId) {
                  this.deleteClothesDraftboxs(this.draId);
                }
              } else {
                this.$message({
                  type: "error",
                  message: response.data.msg,
                });
              }
            });
          })
          .catch(() => {
            // this.$customAlert({message: '取消删除'})
          });
    },
    // 前往办卡
    handleCard() {
      this.$confirm("是否前往办卡吗?衣物信息自动保存至草稿箱", "提示", {
        confirmButtonText: "前往办卡",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (this.orderClothes.selectedClothes.length > 0) {
              // 调用接口进行保存
              let data = {
                realName: this.orderClothes.name,
                phone: this.orderClothes.phone,
                orderDto: this.orderClothes,
              };
              addDraftbox(data).then(() => {
              });
            }
            this.$router.push({
              path: "/home/applyCard",
              query: {
                phone: this.orderClothes.phone,
              },
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消前往",
            });
          });
    },
    // 前往充值
    handleRecharge() {
      this.$confirm("是否前往充值吗?,衣物信息自动保存至草稿箱", "提示", {
        confirmButtonText: "前往充值",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (this.orderClothes.selectedClothes.length > 0) {
              // 调用接口进行保存
              let data = {
                realName: this.orderClothes.name,
                phone: this.orderClothes.phone,
                orderDto: this.orderClothes,
              };
              addDraftbox(data).then(() => {
              });
            }
            this.$router.push({
              path: "/home/charger",
              query: {
                phone: this.orderClothes.phone,
              },
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消前往",
            });
          });
    },
    // 打印收衣订单
    getClothesOrderDetails(orderNumber) {
      getClothesOrderDetails(orderNumber).then((response) => {
        this.orderDetailOption = response.data.data;
        this.orderDetailOptionClothesSize = this.orderDetailOption.clothesList.length;
        // if (this.orderDetailOption.orderPrint) {
        //     for (let i = 0; i < this.orderDetailOption.orderPrint; i++) {
        //         this.printPaper()
        //     }
        // }
        this.printPaper();
        this.printLabel();
      });
    },
    getPrint(type) {
      let defaultName;
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
          "",
          "660FDFD7D9F7F7101A272F2EB57C306A",
          "C94CEE276DB2187AE6B65D56B3FC2848",
          ""
      );
      if (type === 1) {
        //小票
        defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
      } else {
        //水洗唛
        defaultName = "TSC";
      }
      let arr = [];
      let iPrinterCount = LODOP.GET_PRINTER_COUNT();
      for (let i = 0; i < iPrinterCount; i++) {
        arr.push({
          label: LODOP.GET_PRINTER_NAME(i),
          value: i,
        });
      }
      arr.forEach((item) => {
        if (item.label === defaultName) {
          this.printIndex = item.value;
        }
      });
    },

    // 查询数据
    printPaper() {
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
          "",
          "660FDFD7D9F7F7101A272F2EB57C306A",
          "C94CEE276DB2187AE6B65D56B3FC2848",
          ""
      );
      this.getPrint(1);

      LODOP.PRINT_INIT("收银小票"); //首先一个初始化语句
      LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");

      let height = 0;
      //门店信息
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          this.orderDetailOption.storeName
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      height = height + 6;
      //门店电话
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "3mm",
          "门店电话：" + this.orderDetailOption.store.phone
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      //门店地址
      if (this.orderDetailOption.store.address.length > 16) {
        height = height + 4;
        LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "地址：" + this.orderDetailOption.store.address.substring(0, 16)
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        height = height + 4;
        LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            this.orderDetailOption.store.address.substring(16)
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      } else {
        height = height + 4;
        LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "地址：" + this.orderDetailOption.store.address
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      }

      //线
      height = height + 6;
      LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
      //衣物信息标题
      height = height + 2;
      LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "衣物信息");
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
      //订单号条形码
      height = height + 6;
      LODOP.ADD_PRINT_BARCODE(
          height + "mm",
          "14mm",
          "55mm",
          "9mm",
          "128A",
          this.orderDetailOption.orderNumber
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
      //订单号
      height = height + 10;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "No." + this.orderDetailOption.orderNumber
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      height = height + 5;
      //衣物列表：名称 服务 件数 原价 实收
      if (this.orderDetailOption.clothesList.length > 0) {
        LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "名称");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT(height + "mm", "15mm", "75mm", "4mm", "项目");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT(height + "mm", "30mm", "75mm", "4mm", "件数");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT(height + "mm", "45mm", "75mm", "4mm", "原价");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT(height + "mm", "60mm", "75mm", "4mm", "实收");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        height = height + 5;
      }

      this.printList = this.orderDetailOption.clothesList.filter(
          (item) => item.accessoryNumber == null || item.accessoryNumber == ""
      );

      //衣物列表
      for (let i = 0; i < this.printList.length; i++) {
        let clothes = this.printList[i];
        LODOP.ADD_PRINT_TEXT(
            height + i * 5 + 1 + "mm",
            "0mm",
            "75mm",
            "4mm",
            clothes.clothesName.length > 3
                ? clothes.clothesName.substring(0, 3) + "*"
                : clothes.clothesName
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
            height + i * 5 + 1 + "mm",
            "15mm",
            "75mm",
            "4mm",
            clothes.clothesServiceName
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
            height + i * 5 + 1 + "mm",
            "30mm",
            "75mm",
            "4mm",
            clothes.num
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
            height + i * 5 + 1 + "mm",
            "45mm",
            "75mm",
            "4mm",
            clothes.originalPrice
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
            height + i * 5 + 1 + "mm",
            "60mm",
            "75mm",
            "4mm",
            clothes.currentPrice
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      }

      height = height + this.printList.length * 5 + 1;

      //注意：*衣物详细信息请进入洗衣通小程序查看*
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "*衣物详细信息请进入洗衣通小程序查看*"
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

      height = height + 6;
      // 线
      LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

      //收银信息
      height = height + 4;
      LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "收银信息");
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      //收银详情
      height = height + 6;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "客户姓名：" + this.orderDetailOption.orderRealName
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "37.5mm",
          "75mm",
          "4mm",
          "联系电话：" + this.orderDetailOption.orderUserPhone
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      // 充值会员
      if (this.orderDetailOption.userType === 3) {
        height = height + 5;
        LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "会员卡号：" + this.orderDetailOption.cardNo
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
        LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "卡内余额：" + this.orderDetailOption.balance
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      }

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "总计件数：" + this.orderDetailOption.clothesList.length
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "37.5mm",
          "75mm",
          "4mm",
          "原价总额：" + this.orderDetailOption.orderOriginalPrice
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "优惠总额：" + this.orderDetailOption.discountPrice
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "37.5mm",
          "75mm",
          "4mm",
          "实收总额：" + this.orderDetailOption.actuallyPaidPrice
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "收衣店长：" + this.orderDetailOption.storeUserName
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      // LODOP.ADD_PRINT_TEXT(height + "mm", "37.5mm", "75mm", "4mm", "取衣时间：" + this.orderDetailOption.takeTime);
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "37.5mm",
          "75mm",
          "4mm",
          "取衣时间：请咨询店长"
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "收衣时间：" + this.orderDetailOption.orderTime
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      //线
      height = height + 6;
      // 线
      LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

      //二维码
      height = height + 5;
      LODOP.ADD_PRINT_BARCODE(
          height + "mm",
          "25mm",
          "30mm",
          "30mm",
          "QRCode",
          "http://weixin.qq.com/r/6Sk8JNrEeYcGrZ5H93z7"
      );
      height = height + 25;

      //注：扫码进入小程序查看衣物详细信息
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "扫码进入公众号查看衣物详细信息"
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
      //请妥善保管取衣凭证，如丢失请立即前往门店挂失
      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "请妥善保管取衣凭证，如丢失请立即前往门店挂失"
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      //投诉服务电话：400-816-5008
      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "投诉服务电话：400-816-5008"
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

      LODOP.SET_PRINTER_INDEX(this.printIndex);
      LODOP.PRINT();
    },

    // 打印标签
    printLabel() {
      let list = this.orderDetailOption.clothesList;

      let LODOP = getLodop();
      LODOP.SET_LICENSES(
          "",
          "660FDFD7D9F7F7101A272F2EB57C306A",
          "C94CEE276DB2187AE6B65D56B3FC2848",
          ""
      );
      this.getPrint(2);

      for (let i = 0; i < list.length; i++) {
        let clothes = list[i];
        LODOP.PRINT_INIT("水洗唛"); //首先一个初始化语句
        LODOP.SET_PRINT_PAGESIZE(1, "110mm", "23mm", "");
        //一边30mm,65mm
        LODOP.ADD_PRINT_TEXT(
            "0mm",
            "7mm",
            "70mm",
            "10mm",
            "品类：" +
            (clothes.accessoryNumber == "" || clothes.accessoryNumber == null
                ? ""
                : "附件 ") +
            clothes.clothesName +
            " " +
            clothes.clothesServiceName
        ); //然后多个ADD语句及SET语句
        LODOP.ADD_PRINT_TEXT(
            "0mm",
            "70mm",
            "33mm",
            "10mm",
            this.orderDetailOption.storeName
        ); //然后多个ADD语句及SET语句
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
            "5mm",
            "7mm",
            "70mm",
            "10mm",
            "订单：" +
            this.orderDetailOption.orderNumber +
            " " +
            this.orderDetailOption.orderTime
        ); //然后多个ADD语句及SET语句
        LODOP.ADD_PRINT_TEXT(
            "10mm",
            "7mm",
            "70mm",
            "10mm",
            "电话：" +
            this.orderDetailOption.orderUserPhone +
            " " +
            this.orderDetailOption.orderRealName
        ); //然后多个ADD语句及SET语句
        //条形码
        LODOP.ADD_PRINT_BARCODE(
            "5mm",
            "70mm",
            "38mm",
            "10mm",
            "128C",
            clothes.outClothesNum
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
        let desc = "";
        if (clothes.colorName !== "") {
          desc = desc + clothes.colorName + " ";
        }
        if (clothes.brandName !== "") {
          desc = desc + clothes.brandName + " ";
        }
        if (clothes.effects !== "") {
          desc = desc + clothes.effects + " ";
        }
        if (clothes.blemishes !== "") {
          desc = desc + clothes.blemishes;
        }

        LODOP.ADD_PRINT_TEXT("15mm", "7mm", "70mm", "10mm", "描述：" + desc); //然后多个ADD语句及SET语句
        LODOP.ADD_PRINT_TEXT(
            "15mm",
            "70mm",
            "33mm",
            "10mm",
            clothes.outClothesNum
        ); //然后多个ADD语句及SET语句
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINTER_INDEX(this.printIndex);
        LODOP.PRINT();
      }
    },
    getCurrentTime() {
      return this.$moment().format("MMMM Do YYYY, h:mm:ss a");
    },
    // 确认转余额
    enterTransferBalance() {
      this.orderClothes.payWay = null;
      this.choosePaywayIndex = null;
      this.transferBalanceShow = true;
    },
    closeTransferBalance() {
      this.transferBalanceShow = true;
    },
    clickRadio() {
      this.clearCoupon();
      this.radioValue = true;
      this.couponList = this.couponList.map((item) => {
        item.radioValue = false;
        item.ruleShow = false;
        return item;
      });
    },
    clickRadioList(val, id) {
      if (this.couponList[val].radioValue == true) {
        this.couponList[val].radioValue = false;
        this.couponAmount = 0;
        this.chooseCouponList?.forEach((item) => {
          if (item.couponId != id) {
            if (item.couponType == 5) {
              item.couponAmount = item.money;
              this.couponAmount = this.couponAmount + item.money;
            } else {
              this.couponAmount = this.couponAmount + item.couponAmount;
            }
          }
        });

        this.selectedClothes = this.selectedClothes.map((item) => {
          if (item.couponId == id) {
            item.couponId = null;
          }
          return item;
        });
        this.chooseCouponList = this.chooseCouponList.filter(
            (item) => item.couponId != id
        );
      } else {
        this.useCoupon(id, val);
      }
    },
    clickRule(val) {
      this.couponList = this.couponList.map((item, index) => {
        if (index == val) {
          item.ruleShow = !item.ruleShow;
        }
        return item;
      });
    },
    closeCoupon() {
      this.clearCoupon();
      this.couponList = this.couponList.map((item) => {
        item.radioValue = false;
        item.ruleShow = false;
        return item;
      });
      this.couponActive = false;
    },
    enterCoupon() {
      this.couponActive = false;
    },
    closeSettleAccounts() {
      this.$router.push({name: "home"});
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

.prentView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*z-index: 100;*/
  background: #fff;
}

// //////////////////////优惠券样式start
.couponList {
  position: relative;
  height: 100%;
}

.couponList-title {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;

  .line {
    width: 4px;
    background-color: #2277f6;
    margin-right: 10px;
    border-radius: 10px;
    height: 20px;
  }
}

.rule-box {
  font-size: 14px;
  color: #b2b2b2;
}

@keyframes slide-down {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes select-up {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0);
  }
}

.select-open {
  animation: slide-down 0.2s;
  transition: 0.2s;
  transform-origin: 50% 0;
}

.select-close {
  animation: select-up 0.2s;
  transition: 0.2s;
  transform-origin: 50% 0;
}

.couponBox {
  // height: 500px;
  // margin-top: 20px;
  // padding: 15px;
  // display: flex;
  // flex-wrap: wrap;
  // overflow-y: auto;
  height: calc(100vh - 200px);
  overflow: auto;
  font-size: 16px;
  margin: 20px 20px 0 20px;
  color: #000;

  .coupon-content {
    background-color: #fafafb;
    padding: 0 15px 15px;
    border: 1px solid rgba(220, 223, 230, 100);
    border-radius: 3px;
    margin-bottom: 20px;

    .top {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        div {
          font-size: 14px;
        }

        div:nth-child(3) {
          color: #b2b2b2;
          padding: 3px 0;
        }
      }

      .right {
        height: 20px;
        width: 20px;

        .radio {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid rgba(187, 187, 187, 100);
          background-color: rgba(255, 255, 255, 100);
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }

    .center {
      margin: 8px 0;
      height: 0;
      border-bottom: 1px dashed rgba(187, 187, 187, 100);
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      color: rgba(178, 178, 178, 100);
      font-size: 14px;
      padding-bottom: 5px;
      cursor: pointer;

      .icon {
        font-size: 18px;
        width: 20px;
      }
    }
  }

  .nonuse-coupon {
    background-color: #fafafb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 20px;
    border: 1px solid rgba(220, 223, 230, 100);
    border-radius: 3px;
    margin-bottom: 20px;

    .right {
      height: 20px;
      width: 20px;

      .radio {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 100);
        background-color: rgba(255, 255, 255, 100);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  .select-radio {
    background: rgba(34, 119, 246, 100) !important;
    border: none !important;
  }

  .couponItem {
    position: relative;
    width: 200px;
    height: 90px;
    margin-right: 15px;
    margin-top: 10px;
    border: 1px solid #3370ff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #3370ff;

    i {
      position: absolute;
      top: 3px;
      right: -22px;
      width: 80px;
      height: 30px;
      background-color: #3370ff;
      font-size: 12px;
      font-style: normal;
      text-align: center;
      line-height: 30px;
      transform: rotate(45deg);
      color: #fff;
    }

    .selectView {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
      }
    }
  }

  .couponBox {
    height: 500px;
    margin-top: 20px;
    padding: 15px 50px;
    overflow-y: auto;

    .couponItem {
      position: relative;
      width: 200px;
      height: 90px;
      margin-right: 15px;
      margin-top: 10px;
      border: 1px solid #3370ff;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      color: #3370ff;

      i {
        position: absolute;
        top: 3px;
        right: -22px;
        width: 80px;
        height: 30px;
        background-color: #3370ff;
        font-size: 12px;
        font-style: normal;
        text-align: center;
        line-height: 30px;
        transform: rotate(45deg);
        color: #fff;
      }

      .selectView {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.couponList-footer {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;

  .close-button {
    border: 1px solid #dcdfe6;
  }
}

.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  // transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

// /////////////////////优惠券样式end
.orderAddView {
  position: relative;
  background: #fff;
  font-family: PingFangSC-Regular;

  > .topView {
    display: flex;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 20px 25px 10px 25px;
    z-index: 100;

    > .backView {
      height: 30px;
      flex: 1;
    }

    > .aliKoubeiView {
      display: flex;
      height: 30px;
      line-height: 30px;
    }
  }

  > .contentView {
    position: relative;
    height: 100%;
    padding-top: 60px;

    > .leftView {
      height: 100%;

      > .menuView {
        position: relative;
        display: flex;
        padding-left: 30px;
        /*height: 44px;*/
        overflow: hidden;

        > .btnView {
          width: 120px;

          > button {
            width: 106px;
            height: 44px;
            padding: 0;
          }

          // > .btn-service {
          //   background: url("~@/assets/navImages/Order_Switch_Service.png")
          //     center / 106px 44px no-repeat;
          // }
        }

        > .collView {
          /*/deep/ .el-tabs__nav-wrap::after {*/
          /*    position: static !important;*/
          /*}*/
          // flex: 1;
          // margin-right: 12px;
          height: 44px;
          line-height: 44px;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          font-family: PingFangSC-Regular;
          // padding-right: 30px;
          -webkit-transition: -webkit-transform 0.7s;
          border: 1px solid rgba(217, 217, 217, 100);
          width: 802px;
          border-radius: 5px;

          > .categoryItem {
            display: inline-block;
            text-align: center;
            // padding: 0 12px;
            height: 44px;
            width: 100px;
            color: #101010;
            border-right: 1px solid rgba(217, 217, 217, 100);

            > label {
              display: inline-block;
              height: 32px;
              box-sizing: border-box;
              line-height: 32px;
              font-family: PingFangSC-Regular;
              font-size: 15px;
              cursor: pointer;
            }
          }

          .categoryItem:nth-child(8) {
            border: none;
          }

          .select {
            background-color: #3370ff;
            // font-size: 16px;
            color: #fff;
            // border-bottom: 2px solid #3370ff;
          }
        }
      }

      .boxView {
        padding: 15px 22px 20px 22px;
        overflow: auto;

        > .boxItem.serviceItem:hover {
          transform: scale(1.05);
          background: rgba(66, 153, 255, 0.1);
        }

        > .boxItem {
          position: relative;
          display: inline-block;
          width: 205px;
          margin: 0 8px 16px 8px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;
          vertical-align: top;
          transition: transform 0.7s, background 0.7s, font-size 0.7s;

          > .cView {
            display: flex;
            height: 68px;
            line-height: 68px;

            .image {
              width: 30px;
            }

            > .img {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;

              > img {
                width: 50px;
              }
            }

            > .color {
              display: flex;
              justify-content: center;
              align-items: center;

              > .col {
                width: 60px;
                height: 30px;
              }
            }

            > .item {
              ::v-deep .el-badge__content.is-fixed {
                position: absolute;
                top: 10px;
                right: 0px;
                transform: translateY(-50%) translateX(100%);
              }
            }
          }

          > .cView .nameView {
            flex: 1;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #333;
            overflow: hidden;
            padding-right: 8px;
            text-align: center;
          }
        }


        > .serviceItem {
          background: #f8f8f8;

          > .cView {
            /*<!--background: transparent url(https://static.bokao2o.com/wisdomCashier/images/Icon_SortAdd.png) 236px center / 28px 28px no-repeat;-->*/
            padding: 0 20px 0 20px;
          }
        }

        > .effects-clothes {
          // 修改选择框颜色
          /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #FFFFFF !important;
            border-color: #f32823 !important;
            background-color: #f32823 !important;
            box-shadow: -1px 0 0 0 #f32823 !important;
          }

          /deep/ .el-radio-button__inner:hover {
            color: #f32823;
          }

          /deep/ .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
            color: #C0C4CC;
            cursor: not-allowed;
            background-image: none;
            background-color: #FFFFFF;
            border-color: #EBEEF5;
            box-shadow: none;
          }

          //
          ///deep/ .el-checkbox__input.is-focus .el-radio-button__inner {
          //  border-color: #f32823 !important;
          //}
        }
      }

      .icon {
        position: absolute;
        top: -2px;
        right: -2px;
        width: 25px;
        height: 25px;
        background-color: #3370ff;
        border-radius: 0 0 0 100%;
        color: #fff;
        font-weight: 700;
        text-align: center;
        line-height: 25px;
      }

      .clothesInfo {
        display: flex;
        background: #fff;
        padding: 15px 22px 20px 22px;
        overflow: auto;
        height: calc(100vh - 104px);

        > .leftView {
          width: 110px;
          // background: #f8f8f8;
          overflow-x: hidden;
          overflow-y: auto;
          border-radius: 0px 10px 10px 0px;

          > .leftItem {
            position: relative;
            line-height: 20px;
            padding: 20px 5px 20px 23px;
            font-size: 14px;
            color: #333;
            background: #f8f8f8;
            cursor: pointer;
          }

          > .leftItem.select {
            background: #fff;
            color: #3370ff;
            font-family: PingFangSC-Semibold;
          }

          > .leftItem.select:before {
            position: absolute;
            content: "";
            background: #3370ff;
            left: 1px;
            top: 0;
            bottom: 0;
            width: 3px;
          }
        }

        > .conetntView {
          flex: 1;
          overflow-x: hidden;
          overflow-y: auto;
          /*text-align: center;*/
          // background: #f8f8f8;
          padding: 10px;

          > .boxItem.serviceItem:hover {
            transform: scale(1.05);
            background: rgba(66, 153, 255, 0.1);
          }

          > .boxItem {
            position: relative;
            display: inline-block;
            width: 170px;
            margin: 0 8px 16px 8px;
            background: #f8f8f8;
            border-radius: 6px;
            overflow: hidden;
            cursor: pointer;
            vertical-align: top;
            transition: transform 0.7s, background 0.7s, font-size 0.7s;

            > .cView {
              display: flex;
              height: 68px;
              line-height: 68px;
              text-align: center;

              > .image {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                > img {
                  width: 50px;
                }
              }
            }

            > .cView .nameView {
              flex: 1;
              font-family: PingFangSC-Semibold;
              font-size: 16px;
              color: #333;
              overflow: hidden;
              padding-right: 8px;
            }
          }

          > .serviceItem {
            background: #fff;
            border: 1px solid rgba(220, 223, 230, 100);

            > .cView {
              /*<!--background: transparent url(https://static.bokao2o.com/wisdomCashier/images/Icon_SortAdd.png) 200px center / 28px 28px no-repeat;-->*/
              padding: 0 20px 0 20px;
            }
          }
        }
      }
    }

    > .rightView {
      position: absolute;
      top: 45px;
      right: 0;
      padding: 20px 20px 0 0;
      width: 500px;
      height: calc(100vh - 60px);

      > .topView {
        // position: absolute;
        // top: 20px;
        // left: 15px;
        // right: 35px;
        // width: auto;
        // height: 162px;
        // z-index: 10;
        background: #fff;

        .user-info {
          height: 120px;
          border-radius: 5px;
          background-color: rgba(250, 250, 250, 100);
          margin: 10px;
          display: flex;
          align-items: center;
          padding-left: 25px;

          .user-content {
            padding-left: 20px;
            font-size: 14px;

            .phone {
              padding: 4px 0;
            }

            .member {
              display: flex;
              position: relative;

              .el-image {
                position: absolute;
                z-index: 99;
              }

              .member-name {
                background-color: #fbae11;
                color: #fff;
                width: 70px;
                font-size: 12px;
                margin-left: 5px;
                text-align: center;
                height: 18px;
                line-height: 18px;
                border-radius: 9px;
                padding-left: 10px;
              }
            }
          }
        }

        > .tView {
          position: relative;
          line-height: 25px;
          // padding-bottom: 12px;
          border-bottom: 1px dashed #bbb;
          /*width: 100%;*/
          padding: 0 10px 10px;

          > .holderView {
            font-family: PingFangSC-Regular;
            font-size: 15px;
            color: #333;
            line-height: 23px;
          }

          > .dateView {
            font-family: PingFangSC-Regular;
            font-size: 15px;
            color: #666;
          }

          > .orderIdView {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #666;
          }

          > .btn-remark {
            position: absolute;
            top: 10px;
            right: 0;
            font-size: 15px;
            width: 58px;
            height: 28px;
            background: #fff url("~@/assets/navImages/Order_Icon_Remark_N.png") center / 58px no-repeat;
          }
        }

        > .customerView {
          position: relative;
          padding: 12px 0;
          line-height: 28px;
          overflow: hidden;

          > .typeView {
            padding-right: 90px;
            font-family: PingFangSC-Regular;
            font-size: 15px;
            color: #333;
            padding-left: 5px;
            line-height: 28px;

            > .label-tip {
              font-size: 13px;
              color: #3370ff;
              cursor: pointer;
            }
          }
        }
      }

      .orderInfoView {
        position: relative;
        overflow: auto;
        padding: 0 15px 0 15px;
        box-shadow: 0 -3px 8px rgba(220, 220, 220, 0.4);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: #fff;
        background: linear-gradient(#ffffff, #fcfcfc);
        height: calc(100vh - 440px);

        .clothes-title {
          padding-top: 20px;
          display: flex;
          text-align: center;
          color: rgba(137, 137, 137, 100);
          font-size: 14px;
          border-bottom: #e5e5e5 solid 1px;
          padding-bottom: 10px;

          div {
            width: 20%;
          }
        }

        > .memberView {
          //padding: 20px 0;
          text-align: center;

          > .bcView {
            position: relative;
            display: inline-block;
            width: 346px;
            height: 110px;
            text-align: left;
            padding: 10px 10px 10px 12px;
            font-family: PingFangSC-Regular;

            > .cardTypeView {
              position: relative;
              padding-right: 80px;
              display: flex;
              line-height: 30px;
              color: #fff;
              fobtn-closent-family: PingFangSC-Semibold;
              font-size: 18px;
              margin-bottom: 2px;

              > div {
                position: relative;
                display: inline-block;
                padding: 0 2px;

                > .line-1 {
                  position: absolute;
                  left: 0;
                  bottom: 3px;
                  width: 100%;
                  height: 1px;
                  background: #fff;
                }

                > .line-2 {
                  position: absolute;
                  left: 0;
                  bottom: 5px;
                  width: 100%;
                  height: 1px;
                  background: #fff;
                }
              }
            }

            > .textView {
              font-size: 14px;
              display: flex;
              line-height: 30px;
              color: rgba(255, 255, 255, 0.7);

              > label:first-child {
                flex: 2;
              }

              > label:last-child {
                flex: 3;
              }
            }

            > .btn-edit {
              position: absolute;
              width: 40px;
              height: 40px;
              top: 0;
              right: 0;
              background: url("~@/assets/navImages/Icon_Edit_White.png") center /
                28px 28px no-repeat;
            }

            > .btn-topup {
              position: absolute;
              bottom: 10px;
              right: 110px;
              background: rgba(255, 255, 255, 0.2);
              padding: 0 8px;
              height: 26px;
              line-height: 26px;
              border-radius: 6px;
              font-size: 15px;
              color: #fff;
            }
          }

          > .bcView.bcTag_0 {
            background: url("~@/assets/navImages/Order_MemberBc_Blue.png") center / 346px 110px no-repeat;
          }
        }

        .serviceView {
          padding-top: 10px;

          .serviceItem {
            background: #f8f8f8;
            margin-bottom: 12px;
            // padding: 0 20px 5px 20px;
            font-size: 14px;
            border-radius: 6px;

            .info_name {
              padding: 2px 0;
            }

            .text-box {
              border-radius: 4px;
              background-color: rgba(245, 245, 245, 100);
              text-align: center;
              border: 1px solid rgba(217, 217, 217, 100);
              padding: 2px 5px;
              margin-right: 10px;
              color: #686868;
            }

            .tView {
              position: relative;
              padding: 12px 0;
              line-height: 20px;
              // border-bottom: 0.5px dashed #bbb;
              text-align: center;

              .tagsView {
                display: flex;
                // justify-content: space-around;
                align-items: center;
                color: black;
                font-size: 14px;

                > div {
                  width: 20%;

                  p:nth-child(1) {
                    // color: #666;
                    font-size: 14px;
                  }

                  p:nth-child(2) {
                    margin-top: 10px;
                  }
                }
              }

              > .label-number {
                position: absolute;
                left: 0px;
                top: 0;
                padding: 0 3px;
                height: 16px;
                min-width: 14px;
                font-family: DINAlternate-Bold;
                font-size: 14px;
                color: #fff;
                line-height: 16px;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background: red;
              }
            }

            .active {
              // background: #e6a23c;
              color: #3370ff !important;
            }

            > .empView {
              padding: 0 20px 5px;
              display: flex;

              > .empSlotView {
                padding: 5px 0;
                font-family: PingFangSC-Regular;
                text-align: center;

                > .el-col > .editBtn {
                  width: 100%;
                  height: 40px;
                  padding: 0;
                  background: transparent url("~@/assets/navImages/Icon_Edit_blue.png") right center /
                    28px 28px no-repeat;
                }
              }
            }

            > .disView {
              padding: 0 20px 5px;

              > .empView {
                /*padding: 5px 0;*/
                // display: flex;

                > .empSlotView {
                  padding: 5px 0;
                  font-family: PingFangSC-Regular;
                  text-align: center;

                  > .el-col > .editBtn {
                    width: 100%;
                    height: 40px;
                    padding: 0;
                    background: transparent url("~@/assets/navImages/Icon_Edit_blue.png") right center /
                      28px 28px no-repeat;
                  }
                }

                > .list_all {
                  /*width: 100%;*/
                  font-size: 14px;

                  .span_list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
              }

              > .assEmpView {
                padding-top: 3px;

                > .list_all {
                  font-size: 14px;

                  .span_list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 5px;
                    padding-top: 3px;
                  }
                }
              }
            }
          }

          .serviceItem:last-child {
            margin-bottom: 0;
          }
        }
      }

      > .amountView {
        position: absolute;
        right: 20px;
        bottom: 75px;
        padding: 0 15px;
        text-align: right;
        line-height: 55px;
        background: #fff url("~@/assets/navImages/Order_BillBottomBc.png") center bottom / 100% no-repeat;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #999;
        display: flex;
        align-items: center;

        > span {
          font-family: PingFangSC-Semibold;
          font-size: 25px;
          color: #ff5e56;
        }
      }

      .button-box {
      }

      > .btnView {
        display: flex;
        padding-top: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 95%;

        > button {
          flex: 1;
          font-family: PingFangSC-Regular;
          font-size: 17px;
          color: #fff;
          border-radius: 6px;
          // background: #FF5E56;
          height: 44px;
          border: none;
          margin: 0 10px;
        }

        > button:first-child {
          // background: #3370ff;
        }
      }
    }
  }

  > .popView#order-serviceEditPopView > .popView-contentView {
    > .contentView {
      text-align: center;
      padding: 0 35px 20px 25px;

      > .inputView {
        margin-top: 15px;
        background: #f8f8f8;
        padding: 10px;
        line-height: 30px;
        display: flex;

        > label {
          font-size: 14px;
          width: 80px;
          text-align: left;
          color: #333;
        }

        > input {
          flex: 1;
          text-align: right;
          font-size: 15px;
          color: #999;
          background: transparent;
          border: none;
        }
      }

      > .btnView {
        margin-top: 25px;

        > button {
          width: 200px;
          line-height: 44px;
          height: 44px;
          background: #3370ff;
          font-size: 16px;
          color: #fff;
          border-radius: 6px;
        }
      }
    }

    > .btn-del {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 50px;
      height: 30px;
      padding: 0;
      border-radius: 5px;
      line-height: 30px;
      font-size: 13px;
      color: #fff;
      background: #ff5e56;
    }
  }
}

.serve {
  display: flex;
  padding: 5px;

  .nameView {
    flex: 1;
    font-family: SourceHanSansSC-bold;
    font-size: 16px;
    color: #101010;
    overflow: hidden;
    padding-right: 8px;
    font-weight: bold;
    margin: auto 0;
    padding-left: 10px;
  }

  .serve-price {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    font-weight: normal;
    text-align: center;
    padding-right: 15px;
    line-height: 25px;
    font-size: 15px;

    span {
      text-align: right;
      color: #101010;
    }
  }
}

::v-deep .el-dialog__header {
  text-align: center;
}

/deep/ .el-dialog__body {
  overflow: auto;
  // height: 500px;
}

.size-dialog {
  ::v-deep .el-dialog__body {
    height: 500px;
  }
}

.note {
  ::v-deep .el-dialog__body {
    height: 260px;
  }

  ::v-deep .el-textarea__inner {
    height: 200px;
  }
}

.valueWash {
  /deep/ .el-dialog__body {
    overflow: auto;
    // height: 250px;
    text-align: center;

    .el-form {
      width: 300px;
      margin: 0 auto;
    }
  }

  .current_user {
    color: rgba(16, 16, 16, 100);
    font-size: 16px;
    padding-right: 10px;
    width: 125px;
    text-align: right;
    display: inline-block;
  }

  .current_rate {
    color: rgba(16, 16, 16, 100);
    font-size: 16px;
    padding-right: 10px;
    width: 110px;
    text-align: right;
    display: inline-block;
  }

  .span_box {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
  }
}

.activity {
  padding: 20px 0 0 100px;
  font-size: 16px;

  .circle {
    height: 22px;
    width: 22px;
    border-radius: 50px;
    border: 1px #585859 solid;
    margin-left: -25px;
  }

  .details {
    display: flex;
    flex-direction: row;
  }

  .details-item {
    color: #000;
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

label {
  cursor: default;
}

.orderCheckoutView1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  z-index: 100;

  .order-title {
    background-color: rgba(250, 250, 251, 100);
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;

    .title {
      font-size: 24px;
    }

    .close {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      font-size: 30px;
      font-weight: 700;
      color: rgba(144, 147, 153, 100);
    }
  }

  .content {
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);

    > .leftView {
      width: 100%;
      height: 100%;
      background: #fff;

      > .contentView {
        overflow-x: hidden;
        overflow-y: auto;

        > .orderInfoView {
          > .topView {
            padding: 0 30px 15px 30px;
            border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
            background-color: #fff;

            > .timeView {
              line-height: 30px;
              font-size: 14px;
              color: #666;
            }

            > .cardView {
              line-height: 32px;
              color: #333;
              font-size: 14px;

              > label {
                margin-right: 35px;
              }

              > label:last-child {
                margin-right: 0;
              }
            }

            > .discountView {
              cursor: pointer;
              color: #000;
              font-size: 14px;
              margin-top: 10px;
              width: 376px;
              height: 87px;
              border: 1px solid rgba(220, 223, 230, 100);
              border-radius: 5px;

              .top {
                width: 100%;
                height: 40px;
                background-color: rgba(250, 250, 251, 100);
                border-radius: 5px 5px 0px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;

                .title {
                  display: flex;
                  align-items: center;
                }

                .icon {
                  font-size: 24px;
                  color: #3370ff;
                }

                .text {
                  padding-left: 8px;
                }

                .icon2 {
                  font-size: 20px;
                  color: #101010;
                }
              }

              .bottom {
                height: 47px;
                line-height: 46px;
                font-size: 14px;
                padding-left: 14px;
              }

              // .el-select {
              //   width: 200px;
              //   height: 40px;
              //   /*background: #fff;*/
              //   cursor: pointer;

              //   /deep/ > div,
              //   /deep/ input {
              //     height: 100%;
              //   }

              //   /deep/ .el-select__caret {
              //     line-height: 14px;
              //   }

              //   /deep/ .el-input__inner::placeholder {
              //     color: red;
              //     text-align: center;
              //   }

              //   /* 谷歌 */
              //   /deep/ .el-input__inner::-webkit-input-placeholder {
              //     color: red;
              //     text-align: center;
              //   }

              //   /* 火狐 */
              //   .el-input__inner:-moz-placeholder {
              //     color: #fff;
              //     text-align: center;
              //   }

              //   /*ie*/
              //   .el-input__inner:-ms-input-placeholder {
              //     color: #fff;
              //     text-align: center;
              //   }
              // }
            }
          }

          > .itemsView {
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 15px 20px 15px;
            border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);

            > .toPayView {
              margin-top: 15px;

              ul {
                width: 100%;
                display: flex;
                padding: 15px 0;
                margin: 0;

                li {
                  list-style: none;
                  flex: 1;
                  text-align: center;
                  height: 28px;

                  .btn-select {
                    width: 60px;
                    height: 100%;
                    // margin-left:30px;
                    background: transparent url("~@/assets/navImages/Def_Icon_Select_N.png") center /
                      28px no-repeat;
                    vertical-align: middle;
                  }

                  .btn-select.select {
                    background: transparent url("~@/assets/navImages/Def_Icon_Select_S.png") center /
                      28px no-repeat;
                  }
                }
              }

              > .listItem {
                background: #f8f8f8;
                margin-bottom: 10px;
                border-radius: 6px;
                overflow: hidden;

                .masterView {
                  position: relative;
                  display: flex;
                  padding: 20px 0px;
                  // line-height: 28px;

                  > div {
                    flex: 1;
                    line-height: 28px;
                    height: 28px;
                    text-align: center;
                  }

                  > .nameView {
                    > .span-name {
                      font-family: PingFangSC-Semibold;
                      font-size: 15px;
                      color: #333;
                      // margin-right: 15px;
                    }

                    .label-discount {
                      margin-left: 5px;
                      line-height: 16px;
                      color: #ffb826;
                      font-size: 14px;
                      padding: 2px 6px;
                      border-radius: 3px;
                      background: rgba(237, 179, 57, 0.4);
                      font-family: DINAlternate-Bold;
                    }
                  }

                  > .amtView {
                    // text-align: center;
                    font-size: 14px;
                    color: #333;
                  }

                  > .btnView {
                    // display: flex;
                    // width: 100px;
                    // min-width: 100px;

                    > button {
                      flex: 1;
                      height: 28px;
                      font-size: 13px;
                      color: #333;
                    }

                    > button.btn-dyqPay {
                      background: #f8f8f8 url("~@/assets/navImages/Def_Icon_DYQPay_S.png") center /
                        24px no-repeat;
                    }
                  }

                  > .payTypeView {
                    // text-align: right;
                    font-size: 14px;
                    color: #333;

                    > .label-tip {
                      font-weight: 700;
                      font-size: 16px;
                      color: #ff5e56;
                    }
                  }

                  .btn-select {
                    // position: absolute;
                    // left: 0;
                    // top: 0;
                    width: 60px;
                    height: 100%;
                    background: transparent url("~@/assets/navImages/Def_Icon_Select_N.png") center /
                      28px no-repeat;
                  }

                  .btn-select.select {
                    background: transparent url("~@/assets/navImages/Def_Icon_Select_S.png") center /
                      28px no-repeat;
                  }
                }
              }

              > .listItem:last-child {
                margin-bottom: 0;
              }
            }
          }

          > .bView {
            position: relative;
            line-height: 25px;
            text-align: right;
            padding: 15px 40px 15px 35px;
            display: flex;

            > div {
              flex: 1;
            }

            > .btnView {
              position: relative;
              overflow: hidden;

              > button {
                float: left;
                width: 90px;
                text-align: left;
                line-height: 32px;
                height: 32px;
                padding-left: 32px;
                font-size: 14px;
                color: #333;
              }

              > .btn-select {
                background: #fff url("~@/assets/navImages/Def_Icon_Select_N.png") left center /
                  28px no-repeat;
              }

              > button.btn-select.select {
                background: #fff url("~@/assets/navImages/Def_Icon_Select_S.png") left center /
                  28px no-repeat;
              }

              > .btn-split {
                background: #fff url("~@/assets/navImages/Def_Icon_Split_S.png") left center / 24px no-repeat;
              }

              > .btn-split.disable {
                color: #bbb;
                background: #fff url("~@/assets/navImages/Def_Icon_Split_N.png") left center / 24px no-repeat;
              }

              > button.btn-merge {
                background: #fff url("~@/assets/navImages/Def_Icon_Merge_S.png") left center / 24px no-repeat;
              }

              > button.btn-merge.disable {
                color: #bbb;
                background: #fff url("~@/assets/navImages/Def_Icon_Merge_N.png") left center / 24px no-repeat;
              }

              > button.btn-remark {
                position: absolute;
                bottom: 0;
                left: 0;
                min-width: 160px;
                max-width: 320px;
                font-size: 14px;
                color: #666;
                line-height: 20px;
                padding: 0 10px 0 33px;
                text-align: left;
                background: #fff url("~@/assets/navImages/Def_Icon_Edit_Green.png") left center /
                  28px no-repeat;
              }
            }

            > .amtInfoView {
              > .payableAmt {
                font-size: 14px;
                color: #b0b0b0;
              }

              > .realPayAmt {
                font-size: 14px;
                color: #b0b0b0;
              }

              > .toPayAmt {
                font-size: 16px;
                color: #333;

                > label {
                  font-family: DINAlternate-Bold;
                  color: #ff5e56;
                  font-size: 17px;

                  > span {
                    font-size: 20px;
                  }
                }
              }

              > div > label {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    > .rightView {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background: #fff;
      z-index: 10;

      .right-content {
        border-left: #e6e6e6 solid 1px;
        margin: 20px 0;
        height: calc(100vh - 80px);

        > .tView {
          position: relative;
          padding: 0 0 15px 35px;
          font-family: PingFangSC-Semibold;
          font-size: 20px;
          color: #333;
          line-height: 40px;
        }

        > .paymentView {
          padding: 0 30px 15px 30px;
          overflow-x: hidden;
          overflow-y: auto;

          .paymentItem {
            position: relative;
            display: flex;
            padding: 15px 0 15px 25px;
            cursor: pointer;

            #loading-mask {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              /* z-index: 20000; */
              background-color: #47bf7c;
              opacity: 0.4;
              border-radius: 10px;
            }

            #loading-disabled {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              /* z-index: 20000; */
              background-color: #ccc;
              opacity: 0.4;
              border-radius: 10px;
            }

            .iconView {
              margin-right: 28px;
              height: 42px;

              > img {
                width: 42px;
                height: 42px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: #1975ff;
              }
            }

            .textView {
              display: flex;
              flex: 1;
              font-size: 15px;
              font-family: PingFangSC-Medium;
              color: #333;
              line-height: 42px;

              > .label-name {
                flex: 2;
              }

              > .label-amt {
                flex: 1;
                font-size: 13px;
                text-align: right;
                color: #47bf7c;
                padding-right: 10px;
              }
            }
          }
        }

        > .btnView {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          padding: 20px 0 20px 0;
          text-align: center;

          > button {
            width: 260px;
            height: 44px;
            line-height: 44px;
            font-size: 16px;
            color: #fff;
            background: #3370ff;
            border-radius: 6px;
          }
        }
      }

      .receivable-title {
        color: rgba(137, 137, 137, 100);
        font-size: 18px;
        padding-left: 35px;
      }

      .receivable-price {
        color: rgba(40, 40, 40, 100);
        font-size: 25px;
        padding-left: 35px;
      }

      .line {
        height: 0;
        border: 1px solid rgba(230, 230, 230, 100);
        margin: 20px;
      }
    }
  }

  .right-ransfer {
    position: absolute;
    top: 60px;
    right: 0;
    height: calc(100vh - 60px);
    width: 415px;
    background: #fff;
  }
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          /* width: 320px; */
          /* line-height: 40px; */
          /* height: 40px; */
        }

        > button {
          width: 72px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Semibold;
          color: #fff;
          margin-left: 15px;
          background: #3370ff;
          border-radius: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url("~@/assets/navImages/Icon_X_Gray.png") center / 28px 28px no-repeat;
    }
  }
}

p {
  margin: 0;
  padding: 0;
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.f-details {
  width: 100%;
  background-color: #fff;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 8px 16px 8px;

  ol {
    display: flex;
    /*justify-content: space-between;*/
    margin-left: 10px;
    width: 100%;

    li {
      list-style: none;
      font-size: 16px;
      margin-right: 10px;
      color: #b2b4b8;
      text-align: center;
      cursor: pointer;
    }
  }
}

.settle-accounts {
  ::v-deep .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    border-radius: 5px;
  }

  ::v-deep .el-dialog__body {
    height: 550px;
    overflow: hidden;
  }

  // height: 600px;
}

#order-orderEditPopView > .popView-contentView {
  > .textView {
    padding: 0 30px;

    > textarea {
      display: block;
      width: 100%;
      background: #f8f8f8;
      min-height: 220px;
      padding: 15px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      border-radius: 6px;
      border: none;
      outline: none;
      resize: none;
    }
  }

  > .btnView {
    padding: 20px;
    text-align: center;

    > button {
      width: 100px;
      line-height: 44px;
      height: 44px;
      background: #3370ff;
      font-size: 16px;
      color: #fff;
      border-radius: 6px;
    }
  }
}

.quanxiangq {
  border: 3px solid #3370ff;
  width: 320px;
  height: 130px;
  margin-top: 20px;
}

.add-dialog /deep/ .el-icon-close:before {
  color: white;
}

.add-dialog /deep/ .el-dialog__header {
  background-color: #3370ff;
}

.add-dialog /deep/ .el-dialog__title {
  color: white;
}

.receiptCount {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.receiptBth {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quanxiangq:before {
  content: "";
  position: absolute;
  left: 286px;
  bottom: 96px;
  border: 14px solid #3370ff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.quanxiangq:after {
  content: "";
  width: 3px;
  height: 10px;
  position: absolute;
  left: 304px;
  bottom: 110px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.service-dialog /deep/ .el-dialog__title {
  color: #90150E;
  font-weight: bold;
  font-size: 20px;
}


.service-dialog /deep/ .el-dialog__body {
  color: #303133;
  font-size: 16px;
}
</style>
